<template>
    <div
        class="px-4 w-full dF fC f1 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
    >
        <div class="mb-3 dF aC" style="font-size: 24px; gap: 20px">
            <a-icon type="arrow-left" @click="goBack" />
            <strong>{{ project.name }} &nbsp; #{{ project.readableId }}</strong>
            <span
                class="project-button"
                v-if="project.deleted"
                style="background-color: #db5358"
                >Deleted</span
            >
            <span
                class="project-button"
                v-else-if="project.susspended"
                style="background-color: #ffc107"
            >
                Locked
            </span>
            <span
                class="project-button"
                v-else
                style="background-color: #28c791"
            >
                Active</span
            >
        </div>
        <div v-if="changeInPlan" class="py-5">
            <a-steps class="mb-5" :current="current">
                <a-step
                    v-for="item in steps"
                    :key="item.title"
                    :title="item.title"
                >
                    <span slot="description">{{ item.description }}</span>
                </a-step>
            </a-steps>
            <a-card v-if="current === 1" style="text-align: center">
                <div v-if="!packages.length">
                    <a-icon type="loading" style="font-size: 30px" />
                </div>
                <div v-else>
                    <div class="grid-display mt-8" :style="packageStyle">
                        <div
                            class="type-card"
                            v-for="(p, index) in packages"
                            :key="index"
                            @click="selectedPackageId = p.id"
                            style="padding: 30px"
                            :class="{
                                'selected-package': p.id === selectedPackageId,
                            }"
                        >
                            <div
                                class="text-center"
                                style="
                                    color: #000;
                                    font-size: 20px;
                                    margin-top: 10px;
                                    min-height: 60px;
                                "
                            >
                                {{ p.name && p.name.toUpperCase() }}
                            </div>
                            <a-divider />
                            <div class="text-center">
                                <p style="font-size: 30px; color: #000">
                                    ${{ p.price }}/mo {{
                                        project.currency === "CAD" ? "(CAD)" : ""
                                    }}
                                </p>
                                <div
                                    :style="{
                                        'min-height':
                                            project.projectType === 'solo' &&
                                            project.productType === 'lowrise'
                                                ? '190px'
                                                : 'fit-content',
                                    }"
                                >
                                    {{ p.frontCopy }}
                                </div>
                            </div>
                            <a-divider />
                            <div
                                v-if="addOnsPackages.length && p.itemCode !== 'PA-MTNC'"
                                style="text-align: left"
                            >
								<strong
                                    style="
                                        font-size: 18px;
                                    "
                                    >Add Ons
                                </strong>
                                <div
                                    v-for="(
                                        addOn, addOnIndex
                                    ) in addOnsPackages"
                                    :key="addOnIndex"
                                >
									<a-checkbox
										v-if="addOn.selected"
										:checked="addOn.selected"
										:disabled="addOn.selected"
										class="py-2"
		                            >
		                                <strong>
											{{ addOn.name }}
		                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
		                                        <template slot="title">
			                                            {{addOn.description}}
		                                        </template>
		                                        <a-icon
		                                            type="question-circle"
		                                            style="font-size: 14px"
		                                        />
		                                    </a-tooltip>
										</strong
		                                ><span v-if="addOn.subtitle"> - </span>
		                                {{ addOn.subtitle }}
		                            </a-checkbox>
                                    <a-checkbox
										v-else
										@change="onCheckboxChange($event,addOn,p.id)"
										class="py-2"
                                    >
                                        <strong>
											{{ addOn.name }}
		                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
		                                        <template slot="title">
			                                            {{addOn.description}}
		                                        </template>
		                                        <a-icon
		                                            type="question-circle"
		                                            style="font-size: 14px"
		                                        />
		                                    </a-tooltip>
										</strong
                                        ><span v-if="addOn.subtitle"> - </span>
                                        {{ addOn.subtitle }}
                                    </a-checkbox>
                                </div>
                            </div>
                            <a-divider v-if="p.itemCode !== 'PA-MTNC'"/>
                            <div style="text-align: left" v-if="p.itemCode !== 'PA-MTNC'">
                                <div
                                    class="new-package-inputs dF"
                                    style="justify-content: space-between"
                                >
                                    <a-popover placement="right">
                                        <template slot="title">
                                            <h3
                                                class="text-primary text-center"
                                            >
                                                {{
                                                    instance.productType ==
                                                    "highrise"
                                                        ? "DOOR FEES"
                                                        : "LOT FEES"
                                                }}
                                            </h3>
                                        </template>
                                        <template slot="content">
                                            <div
                                                style="
                                                    text-align: justify;
                                                    width: 400px;
                                                "
                                            >
                                                Includes 50 lots/doors per
                                                development project. Additional
                                                lots/doors are charged on a per
                                                door basis starting at $30 per
                                                door.
                                            </div>
                                            <div class="modal-list d-flex my-2">
                                                <ul class="p-0">
                                                    <li
                                                        class="modal-list-title"
                                                    >
                                                        Door Fee
                                                    </li>
                                                    <li>First 50</li>
                                                    <li>51 – 100</li>
                                                    <li>101 – 150</li>
                                                    <li>151 – 200</li>
                                                    <li>201+</li>
                                                </ul>
                                                <ul>
                                                    <li
                                                        class="modal-list-title"
                                                    >
                                                        Billed at
                                                    </li>
                                                    <li>$0</li>
                                                    <li>$30</li>
                                                    <li>$25</li>
                                                    <li>$20</li>
                                                    <li>$15</li>
                                                </ul>
                                            </div>
                                            <div
                                                style="
                                                    text-align: justify;
                                                    width: 400px;
                                                "
                                            >
                                                Each project subscription is
                                                inclusive of 50 lots/doors.
                                                Thereafter, a per door charge
                                                applies. The charge is based on
                                                the number of lots/doors to be
                                                processed and supported in your
                                                development project. The Door
                                                fee is a one-time fee that is
                                                over and above your monthly
                                                subscription fee and it is
                                                charged upon initial project set
                                                up.
												Note: Currency rates apply.
                                            </div>
                                        </template>
                                        Additional Number of
                                        {{
                                            project.productType === "highrise"
                                                ? "doors"
                                                : "lots"
                                        }}
                                        <a-icon
                                            type="question-circle"
                                            style="
                                                font-size: 12px;
                                                color: black;
                                                margin-left: 5px;
                                            "
                                        />
                                    </a-popover>

                                    <a-input
                                        v-model="lotsOrDoors"
                                        size="small"
                                        type="number"
                                        :min="0"
                                        style="width: 60px"
                                    >
                                    </a-input>
                                </div>
                                <b>{{
                                    project.productType === "highrise"
                                        ? "Doors"
                                        : "Lots"
                                }}</b>
                                - Input the number of
                                {{
                                    project.productType === "highrise"
                                        ? "doors"
                                        : "lots"
                                }}
                                in your development project.
                            </div>

                            <a-divider v-if="p.itemCode !== 'PA-MTNC'"/>
                            <div style="text-align: left" v-if="p.itemCode !== 'PA-MTNC'">
                                <div
                                    class="new-package-inputs dF"
                                    style="justify-content: space-between"
                                >
                                    <span>
                                        Additional Training Hour Blocks
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Can be purchased in 4 hour
                                                blocks @ $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="font-size: 12px"
                                            />
                                        </a-tooltip>
                                    </span>
                                    <a-input
                                        v-model="trainingHourBlocks"
                                        size="small"
                                        type="number"
                                        :min="0"
                                        style="width: 60px"
                                    >
                                    </a-input>
                                </div>
                                <b>Training</b> - Input the training hour blocks.
                            </div>

                            <div class="mt-5 text-center">
                                <div v-if="p.id === project.package.id">
                                    <h5>CURRENT PLAN</h5>
                                </div>
                                <a-button
                                    v-else
                                    @click="selectedPackageId = p.id"
                                    style="width: 100px;"
                                    :class="{
                                        'selected-package':
                                            p.id === selectedPackageId,
                                    }"
                                    >SELECT</a-button
                                >
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <a-button @click="showAllFeatures = !showAllFeatures">
                            <a-icon
                                v-if="!showAllFeatures"
                                type="down"
                                style=""
                            />
                            <a-icon v-if="showAllFeatures" type="up" />
                            SHOW ALL FEATURES
                        </a-button>

                        <div
                            v-if="showAllFeatures"
                            class="grid-display mt-5"
                            :style="packageStyle"
                        >
                            <div
                                class="type-card"
                                v-for="p in packages"
                                :key="p.id"
                                style="
                                    padding: 10px 20px 20px 30px;
                                    text-align: left;
                                "
                            >
                                <AllFeaturesList :package="p.name" :packageCode="p.itemCode"/>
                            </div>
                        </div>
                    </div>

                    <div class="dF jE w-full mt-5 mb-5">
                        <a-button-group>
                            <a-button
                                @click="
                                    changeInPlan = false;
                                    current = 1;
                                "
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                :disabled="!selectedPackageId"
                                @click="next"
                                type="primary"
                                style="width: 100px"
                                >NEXT</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
            <a-card v-else-if="current === 2">
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <h5 class="text-dark">How would you like to pay?</h5>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
                <div class="profile">
                    <a-row v-if="instancePriceDetails">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Package options
                                <span
                                    class="text-primary ml-5"
                                    @click="current = 1"
                                    style="cursor: pointer"
                                >
                                    Change
                                </span>
                            </b>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Price ({{
                                    instancePriceDetails.currency || "USD"
                                }})</b
                            >
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <b
                                >Subtotal ({{
                                    instancePriceDetails.currency || "USD"
                                }})</b
                            >
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />
                    <a-row v-if="instancePriceDetails && instancePriceDetails.package && instancePriceDetails.package">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <div class="text-primary">RECURRING FEES</div>
                            {{ instancePriceDetails.package && instancePriceDetails.package.name }}
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b>
                                ${{ instancePriceDetails.package && instancePriceDetails.package.price }}/mth
                            </b>
                            <div>
                                (billed
                                {{ instancePriceDetails.package && instancePriceDetails.package.recurrence }})
                            </div>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                            class="text-black"
                        >
                            <b>${{ instancePriceDetails.package && instancePriceDetails.package.price }}</b>
                        </a-col>
                    </a-row>
                    <div v-if="$mq != 'sm'">
                        <a-row
                            v-for="(additional, index) in additionalPackages"
                            :key="index"
                            class="mt-3"
                        >
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <span
                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
                                >
                                    <div class="text-primary">
                                        ADD ON FEES
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Onboarding'">
                                    <div class="text-primary">
                                        ONE TIME FEES
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                One time fee (Applicable if you
                                                are a first time user of
                                                Bildhive)
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Lot/Door Fee'">
                                    <div class="text-primary">
                                        <a-popover placement="right">
                                            <template slot="title">
                                                <h3
                                                    class="
                                                        text-primary text-center
                                                    "
                                                >
                                                    {{
                                                        instance.productType ==
                                                        "highrise"
                                                            ? "DOOR FEES"
                                                            : "LOT FEES"
                                                    }}
                                                </h3>
                                            </template>
                                            <template slot="content">
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Includes 50 lots/doors per
                                                    development project.
                                                    Additional lots/doors are
                                                    charged on a per door basis
                                                    starting at $30 per door.
                                                </div>
                                                <div
                                                    class="
                                                        modal-list
                                                        d-flex
                                                        my-2
                                                    "
                                                >
                                                    <ul class="p-0">
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Door Fee
                                                        </li>
                                                        <li>First 50</li>
                                                        <li>51 – 100</li>
                                                        <li>101 – 150</li>
                                                        <li>151 – 200</li>
                                                        <li>201+</li>
                                                    </ul>
                                                    <ul>
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Billed at
                                                        </li>
                                                        <li>$0</li>
                                                        <li>$30</li>
                                                        <li>$25</li>
                                                        <li>$20</li>
                                                        <li>$15</li>
                                                    </ul>
                                                </div>
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Each project subscription is
                                                    inclusive of 50 lots/doors.
                                                    Thereafter, a per door
                                                    charge applies. The charge
                                                    is based on the number of
                                                    lots/doors to be processed
                                                    and supported in your
                                                    development project. The
                                                    Door fee is a one-time fee
                                                    that is over and above your
                                                    monthly subscription fee and
                                                    it is charged upon initial
                                                    project set up.
													Note: Currency rates apply.
                                                </div>
                                            </template>
                                            ADDITIONAL
                                            {{
                                                project.productType ==
                                                "highrise"
                                                    ? "DOOR"
                                                    : "LOT"
                                            }}
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-popover>
                                    </div>
                                    {{
                                        project.productType == "highrise"
                                            ? `${lotsOrDoors} doors`
                                            : `${lotsOrDoors} lots`
                                    }}
                                    total
                                </span>
                                <span
                                    v-else-if="additional.name === 'Training Hours'"
                                >
                                    <div class="text-primary">
                                        TRAINING HOUR BLOCKS
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Additional training hours are
                                                charged in 4 hour blocks @
                                                $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.quantity }}
                                    Blocks ({{ additional.quantity * 4 }} Hours)
                                </span>
								<span
                                    v-else-if="additional.name === 'SMS'"
                                >
                                    <div class="text-primary">
                                        ADD ON FEES
                                    </div>
                                    SMS Profile Setup
                                </span>
								<span
                                    v-else
                                >
                                    <div class="text-primary tT">
                                        {{ additional.name }}
                                    </div>
                                    {{ additional.quantity }}
                                </span>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <div
                                    v-if="additional.name === 'Training Hours'"
                                >
                                    <strong>
                                        ${{
                                            +(additional.price /
                                            additional.quantity).toFixed(2)
                                        }}/block(4hours)
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else-if=" additional.name === 'Lot/Door Fee'">
                                    <strong>
                                        ${{
                                            +(
                                                additional.price /
                                                parseInt(lotsOrDoors)
                                            ).toFixed(2)
                                        }}
                                        {{
                                            project.productType == "highrise"
                                                ? "/door"
                                                : "/lot"
                                        }}
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else>
                                    <strong>
                                        ${{
                                            additional.price &&
                                            additional.price.toLocaleString(
                                                "en-US",
                                                {
                                                    useGrouping: true,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )
                                        }}{{
                                            additional.recurrence === "onetime"
                                                ? ""
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "/month"
                                                : "/year"
                                        }}
                                    </strong>
                                    <div>
                                        ({{
                                            additional.recurrence === "onetime"
                                                ? "billed at time of set up"
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "billed monthly"
                                                : "billed yearly"
                                        }})
                                    </div>
                                </div>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b
                                    >${{
                                        additional.price &&
                                        additional.price.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    }}</b
                                >
                            </a-col>
                        </a-row>
                    </div>
                    <hr
                        v-if="$mq != 'sm'"
                        style="margin-left: -24px; margin-right: -24px"
                    />

                    <a-row v-if="instancePriceDetails">
						<a-col :span="$mq == 'sm' ? 6 : 2" />
                        <a-col
                            :span="$mq == 'sm' ? 6 : 14"
                            style="text-align: right"
                        >
                            <p>Subtotal before tax</p>
                            <p v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">Deductions <span>({{ instancePriceDetails.deductions.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">Discount <span>({{ instancePriceDetails.discounts.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">Tax <span>({{ instancePriceDetails.tax.map(t => t.name).join(", ") }})</span></p>
                            <p>Have a coupon code?</p>
                            <p><b>Total Amount</b></p>
                            <p>Currency</p>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <p>
                               ${{
                                    instancePriceDetails.subtotal &&
                                    instancePriceDetails.subtotal.toLocaleString(
                                        "en-US",
                                        {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">
                                -${{
                                    instancePriceDetails.deductions && instancePriceDetails.deductions
                                        .reduce((acc, deduction) => {
                                            return acc + deduction.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">
                                -${{
									instancePriceDetails.discounts &&
                                    instancePriceDetails.discounts
                                        .reduce((acc, discount) => {
                                            return acc + discount.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                }}
                            </p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">
                                ${{
									instancePriceDetails.tax &&
                                    instancePriceDetails.tax
                                        .reduce((acc, tax) => {
                                            return acc + tax.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                }}
                            </p>

                            <p>
                                <span
                                    class="dF aC ml-3"
                                    style="height: 24px; justify-content: end"
                                >
                                    <a-form-item
                                        style="width: 160px; margin: 0 30px 0 0"
                                        has-feedback
                                        :validate-status="validCouponCode"
                                    >
                                        <a-input
                                            id="couponCode"
                                            v-model="couponCode"
                                            size="small"
                                        />
                                    </a-form-item>
                                    <span
                                        class="text-primary"
                                        @click="fetchPriceDetails"
                                        style="cursor: pointer"
                                    >
                                        Apply</span
                                    >
                                </span>
                            </p>
                            <p>
                                <b>
                                    ${{
                                        instancePriceDetails.total &&
                                        instancePriceDetails.total.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    }}
                                </b>
                            </p>
                            <p>{{ instancePriceDetails.currency || "USD" }}</p>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
				<a-form-model-item
                    label="Select Payment Mode"
                    prop="paymentMode"
                >
					<a-radio-group v-model="paymentMode">
						<a-radio-button value="online">
							Online
						</a-radio-button>
						<a-radio-button value="offline">
							Offline
						</a-radio-button>
					</a-radio-group>
                </a-form-model-item>
				<div v-if="instance.paymentMode === 'online'">
					<a-form
	                    class="profile mt-5"
	                    v-if="paymentMethods.length"
	                >
	                    <a-row v-if="$mq != 'sm'">
	                        <a-col :span="$mq == 'sm' ? 2 : 2" />
	                        <a-col :span="$mq == 'sm' ? 8 : 8">
	                            <strong> Credit Card Number </strong>
	                        </a-col>
	                        <a-col :span="$mq == 'sm' ? 8 : 8">
	                            <strong>Cardholder Name </strong>
	                        </a-col>
	                        <a-col :span="4">
	                            <strong>Expiry Date </strong></a-col
	                        >
	                    </a-row>
	                    <hr
	                        v-if="$mq != 'sm'"
	                        style="margin-left: -24px; margin-right: -24px"
	                    />
	                    <div
	                        v-for="card in paymentMethods"
	                        :key="card.id"
	                    >
	                        <a-row>
	                            <a-col :span="$mq == 'sm' ? 2 : 1">
	                                <a-radio
	                                    :value="card.id"
	                                    @change="onChange"
	                                    :checked="card.id === selectedPaymentMethod"
	                                />
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 2 : 2">
	                                <img
	                                    v-if="
	                                        card &&
	                                        card.details &&
	                                        card.details.ccBrand === 'visa'
	                                    "
	                                    alt="Visa"
	                                    src="@/assets/visa.svg"
	                                    width="50px"
	                                />
	                                <img
	                                    v-else-if="
	                                        card &&
	                                        card.details &&
	                                        card.details.ccBrand === 'mc'
	                                    "
	                                    alt="MasterCard"
	                                    src="@/assets/mc.svg"
	                                    width="50px"
	                                />
	                                <img
	                                    v-else-if="
	                                        card &&
	                                        card.details &&
	                                        card.details.ccBrand === 'amex'
	                                    "
	                                    alt="American Express"
	                                    src="@/assets/amex.svg"
	                                    width="50px"
	                                />
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 6 : 6">
	                                {{ cardNumberDetails(card.details) }}
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 6 : 8">
	                                {{ card.details.cardName }}
	                            </a-col>
	                            <a-col :span="5">
	                                {{ card.details.expiry }}
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 2 : 1" class="dF">
	                                <div
	                                    class="mr-4 edit-delete"
	                                    @click="editCard(card)"
	                                >
										<a-icon type="edit" />
	                                </div>
	                                <div
	                                    class="edit-delete"
	                                    @click="deleteCard(card)"
	                                    style="color: var(--danger)"
	                                >
										<a-icon type="delete" style="color:red" />
	                                </div>
	                            </a-col>
	                        </a-row>
	                        <hr
	                            style="margin-left: -24px; margin-right: -24px"
	                        />
	                    </div>
	                </a-form>
					<h5
	                    class="pl-3 pt-3"
	                    style="color: var(--orange); cursor: pointer"
	                    @click="resetPaymentMethodModalData, (paymentMethodModal.show = true)"
	                >
	                    <a-icon type="plus-circle" /> &nbsp;&nbsp;Add a payment
	                    method
	                </h5>
				</div>
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <div class="dF jE w-full m-3">
                        <a-button-group>
                            <a-button
                                @click="current--"
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                @click="next"
                                :loading="loadModal"
                                type="primary"
                                style="width: 120px"
                                >COMPLETE</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
        </div>
		<div v-else-if="updateAddons" class="py-5">
            <a-steps class="mb-5" :current="current">
                <a-step
                    v-for="item in addOnSteps"
                    :key="item.title"
                    :title="item.title"
                >
                    <span slot="description">{{ item.description }}</span>
                </a-step>
            </a-steps>
            <a-card v-if="current === 1" style="text-align: center">
                <div v-if="!addOnsPackages.length">
                    <a-icon type="loading" style="font-size: 30px" />
                </div>
                <div v-else style="width: 50%; margin: auto;">
                    <div
                        v-if="addOnsPackages.length"
                        style="text-align: left"
                    >
						<strong
							style=" font-size: 18px; "
                            >Add Ons
                        </strong>
                        <div
                            v-for="(
                                addOn, addOnIndex
                            ) in addOnsPackages"
                            :key="addOnIndex"
                        >
                            <a-checkbox
								v-if="addOn.selected"
								:checked="addOn.selected"
								:disabled="addOn.selected"
								class="py-2"
                            >
                                <strong>
									{{ addOn.name }}
                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
                                        <template slot="title">
	                                            {{addOn.description}}
                                        </template>
                                        <a-icon
                                            type="question-circle"
                                            style="font-size: 14px"
                                        />
                                    </a-tooltip>
								</strong
                                ><span v-if="addOn.subtitle"> - </span>
                                {{ addOn.subtitle }}
                            </a-checkbox>
							<a-checkbox
								v-else
								@change="onAddOnCheckboxChange($event,addOn)"
								class="py-2"
                            >
                                <strong>
									{{ addOn.name }}
                                    <a-tooltip v-if="addOn.description" overlayClassName="change-tooltip-color" >
                                        <template slot="title">
	                                            {{addOn.description}}
                                        </template>
                                        <a-icon
                                            type="question-circle"
                                            style="font-size: 14px"
                                        />
                                    </a-tooltip>
								</strong
                                ><span v-if="addOn.subtitle"> - </span>
                                {{ addOn.subtitle }}
                            </a-checkbox>
                        </div>
                    </div>
                    <a-divider/>
                    <div style="text-align: left">
                        <div
                            class="new-package-inputs dF"
                            style="justify-content: space-between"
                        >
                            <a-popover placement="right">
                                <template slot="title">
                                    <h3
                                        class="text-primary text-center"
                                    >
                                        {{
                                            instance.productType ==
                                            "highrise"
                                                ? "DOOR FEES"
                                                : "LOT FEES"
                                        }}
                                    </h3>
                                </template>
                                <template slot="content">
                                    <div
                                        style="
                                            text-align: justify;
                                            width: 400px;
                                        "
                                    >
                                        Includes 50 lots/doors per
                                        development project. Additional
                                        lots/doors are charged on a per
                                        door basis starting at $30 per
                                        door.
                                    </div>
                                    <div class="modal-list d-flex my-2">
                                        <ul class="p-0">
                                            <li
                                                class="modal-list-title"
                                            >
                                                Door Fee
                                            </li>
                                            <li>First 50</li>
                                            <li>51 – 100</li>
                                            <li>101 – 150</li>
                                            <li>151 – 200</li>
                                            <li>201+</li>
                                        </ul>
                                        <ul>
                                            <li
                                                class="modal-list-title"
                                            >
                                                Billed at
                                            </li>
                                            <li>$0</li>
                                            <li>$30</li>
                                            <li>$25</li>
                                            <li>$20</li>
                                            <li>$15</li>
                                        </ul>
                                    </div>
                                    <div
                                        style="
                                            text-align: justify;
                                            width: 400px;
                                        "
                                    >
                                        Each project subscription is
                                        inclusive of 50 lots/doors.
                                        Thereafter, a per door charge
                                        applies. The charge is based on
                                        the number of lots/doors to be
                                        processed and supported in your
                                        development project. The Door
                                        fee is a one-time fee that is
                                        over and above your monthly
                                        subscription fee and it is
                                        charged upon initial project set
                                        up.
										Note: Currency rates apply.
                                    </div>
                                </template>
                                Additional Number of
                                {{
                                    project.productType === "highrise"
                                        ? "doors"
                                        : "lots"
                                }}
                                <a-icon
                                    type="question-circle"
                                    style="
                                        font-size: 12px;
                                        color: black;
                                        margin-left: 5px;
                                    "
                                />
                            </a-popover>

                            <a-input
                                v-model="lotsOrDoors"
                                size="small"
                                type="number"
                                :min="0"
                                style="width: 60px"
                            >
                            </a-input>
                        </div>
                        <b>{{
                            project.productType === "highrise"
                                ? "Doors"
                                : "Lots"
                        }}</b>
                        - Input the number of
                        {{
                            project.productType === "highrise"
                                ? "doors"
                                : "lots"
                        }}
                        in your development project.
                    </div>

                    <a-divider/>
                    <div style="text-align: left">
                        <div
                            class="new-package-inputs dF"
                            style="justify-content: space-between"
                        >
                            <span>
                                Additional Training Hour Blocks
                                <a-tooltip overlayClassName="change-tooltip-color">
                                    <template slot="title">
                                        Can be purchased in 4 hour
                                        blocks @ $500/block.
										<br/>
										Note: Currency rates apply.
                                    </template>
                                    <a-icon
                                        type="question-circle"
                                        style="font-size: 12px"
                                    />
                                </a-tooltip>
                            </span>
                            <a-input
                                v-model="trainingHourBlocks"
                                size="small"
                                type="number"
                                :min="0"
                                style="width: 60px"
                            >
                            </a-input>
                        </div>
                        <b>Training</b> - Input the training hour blocks.
                    </div>

                    <div class="dF jE w-full mt-5 mb-5">
                        <a-button-group>
                            <a-button
                                @click="updateAddons = false;current = 1; couponCode = ''"
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                :disabled="addOnNextButtonDisabled"
                                @click="fetchAddOnPriceDetails"
                                type="primary"
                                style="width: 100px"
                                >NEXT</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
            <a-card v-else-if="current === 2">
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <h5 class="text-dark">How would you like to pay?</h5>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
                <div class="profile">
                    <a-row v-if="instancePriceDetails">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Package options
                                <span
                                    class="text-primary ml-5"
                                    @click="current = 1"
                                    style="cursor: pointer"
                                >
                                    Change
                                </span>
                            </b>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b
                                >Price ({{
                                    instancePriceDetails.currency || "USD"
                                }})</b
                            >
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <b
                                >Subtotal ({{
                                    instancePriceDetails.currency || "USD"
                                }})</b
                            >
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                    <hr style="margin-left: -24px; margin-right: -24px"/>
                    <a-row v-if="instancePriceDetails && instancePriceDetails.package && instancePriceDetails.package">
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <div class="text-primary">RECURRING FEES</div>
                            {{ instancePriceDetails.package && instancePriceDetails.package.name }}
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 6 : 8">
                            <b>
                                ${{ instancePriceDetails.package && instancePriceDetails.package.price }}/mth
                            </b>
                            <div>
                                (billed
                                {{ instancePriceDetails.package && instancePriceDetails.package.recurrence }})
                            </div>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                            class="text-black"
                        >
                            <b>${{ instancePriceDetails.package && instancePriceDetails.package.price }}</b>
                        </a-col>
                    </a-row>
                    <div>
                        <a-row
                            v-for="(additional, index) in additionalPackages"
                            :key="index"
                            class="mt-3"
                        >
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <span
                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
                                >
                                    <div class="text-primary">
                                        ADD ON FEES
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Onboarding'">
                                    <div class="text-primary">
                                        ONE TIME FEES
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                One time fee (Applicable if you
                                                are a first time user of
                                                Bildhive)
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.name }}
                                </span>
                                <span v-else-if="additional.name === 'Lot/Door Fee'">
                                    <div class="text-primary">
                                        <a-popover placement="right">
                                            <template slot="title">
                                                <h3
                                                    class="
                                                        text-primary text-center
                                                    "
                                                >
                                                    {{
                                                        instance.productType ==
                                                        "highrise"
                                                            ? "DOOR FEES"
                                                            : "LOT FEES"
                                                    }}
                                                </h3>
                                            </template>
                                            <template slot="content">
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Includes 50 lots/doors per
                                                    development project.
                                                    Additional lots/doors are
                                                    charged on a per door basis
                                                    starting at $30 per door.
                                                </div>
                                                <div
                                                    class="
                                                        modal-list
                                                        d-flex
                                                        my-2
                                                    "
                                                >
                                                    <ul class="p-0">
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Door Fee
                                                        </li>
                                                        <li>First 50</li>
                                                        <li>51 – 100</li>
                                                        <li>101 – 150</li>
                                                        <li>151 – 200</li>
                                                        <li>201+</li>
                                                    </ul>
                                                    <ul>
                                                        <li
                                                            class="
                                                                modal-list-title
                                                            "
                                                        >
                                                            Billed at
                                                        </li>
                                                        <li>$0</li>
                                                        <li>$30</li>
                                                        <li>$25</li>
                                                        <li>$20</li>
                                                        <li>$15</li>
                                                    </ul>
                                                </div>
                                                <div
                                                    style="
                                                        text-align: justify;
                                                        width: 400px;
                                                    "
                                                >
                                                    Each project subscription is
                                                    inclusive of 50 lots/doors.
                                                    Thereafter, a per door
                                                    charge applies. The charge
                                                    is based on the number of
                                                    lots/doors to be processed
                                                    and supported in your
                                                    development project. The
                                                    Door fee is a one-time fee
                                                    that is over and above your
                                                    monthly subscription fee and
                                                    it is charged upon initial
                                                    project set up.
													Note: Currency rates apply.
                                                </div>
                                            </template>
                                            ADDITIONAL
                                            {{
                                                project.productType ==
                                                "highrise"
                                                    ? "DOOR"
                                                    : "LOT"
                                            }}
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-popover>
                                    </div>
                                    {{
                                        project.productType == "highrise"
                                            ? `${lotsOrDoors} doors`
                                            : `${lotsOrDoors} lots`
                                    }}
                                    total
                                </span>
                                <span
                                    v-else-if="additional.name === 'Training Hours'"
                                >
                                    <div class="text-primary">
                                        TRAINING HOUR BLOCKS
                                        <a-tooltip overlayClassName="change-tooltip-color">
                                            <template slot="title">
                                                Additional training hours are
                                                charged in 4 hour blocks @
                                                $500/block.
												<br/>
												Note: Currency rates apply.
                                            </template>
                                            <a-icon
                                                type="question-circle"
                                                style="
                                                    font-size: 12px;
                                                    color: black;
                                                    margin-left: 5px;
                                                "
                                            />
                                        </a-tooltip>
                                    </div>
                                    {{ additional.quantity }}
                                    Blocks ({{ additional.quantity * 4 }} Hours)
                                </span>
								<span
                                    v-else
                                >
                                    <div class="text-primary tT">
                                        {{ additional.name }}
                                    </div>
                                    {{ additional.quantity }}
                                </span>
                            </a-col>
                            <a-col :span="$mq == 'sm' ? 6 : 8">
                                <div
                                    v-if="additional.name === 'Training Hours'"
                                >
                                    <strong>
                                        ${{
                                            +(additional.price /
                                            additional.quantity).toFixed(2)
                                        }}/block(4hours)
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else-if=" additional.name === 'Lot/Door Fee'">
                                    <strong>
                                        ${{
                                            +(
                                                additional.price /
                                                parseInt(lotsOrDoors)
                                            ).toFixed(2)
                                        }}
                                        {{
                                            project.productType == "highrise"
                                                ? "/door"
                                                : "/lot"
                                        }}
                                    </strong>
                                    <div>(billed at time of purchase)</div>
                                </div>
                                <div v-else>
                                    <strong>
                                        ${{
                                            additional.price &&
                                            additional.price.toLocaleString(
                                                "en-US",
                                                {
                                                    useGrouping: true,
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }
                                            )
                                        }}{{
                                            additional.recurrence === "onetime"
                                                ? ""
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "/month"
                                                : "/year"
                                        }}
                                    </strong>
                                    <div>
                                        ({{
                                            additional.recurrence === "onetime"
                                                ? "billed at time of set up"
                                                : additional.recurrence ===
                                                  "monthly"
                                                ? "billed monthly"
                                                : "billed yearly"
                                        }})
                                    </div>
                                </div>
                            </a-col>
                            <a-col
                                :span="$mq == 'sm' ? 4 : 6"
                                style="text-align: right"
                                class="text-black"
                            >
                                <b
                                    >${{
                                        additional.price &&
                                        additional.price.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    }}</b
                                >
                            </a-col>
                        </a-row>
                    </div>
                    <hr style="margin-left: -24px; margin-right: -24px"/>
                    <a-row v-if="instancePriceDetails">
						<a-col :span="$mq == 'sm' ? 6 : 2" />
                        <a-col
                            :span="$mq == 'sm' ? 6 : 14"
                            style="text-align: right"
                        >
                            <p>Subtotal before tax</p>
                            <p v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">Deductions <span>({{ instancePriceDetails.deductions.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">Discount <span>({{ instancePriceDetails.discounts.map(d => d.name).join(", ") }})</span></p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">Tax <span>({{ instancePriceDetails.tax.map(t => t.name).join(", ") }})</span></p>
                            <p>Have a coupon code?</p>
                            <p><b>Total Amount</b></p>
                            <p>Currency</p>
                        </a-col>
                        <a-col
                            :span="$mq == 'sm' ? 4 : 6"
                            style="text-align: right"
                        >
                            <p>
                               ${{
                                    instancePriceDetails.subtotal &&
                                    instancePriceDetails.subtotal.toLocaleString(
                                        "en-US",
                                        {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.deductions && instancePriceDetails.deductions.length">
                                -${{
                                    instancePriceDetails.deductions && instancePriceDetails.deductions
                                        .reduce((acc, deduction) => {
                                            return acc + deduction.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) || (0).toFixed(2)
                                }}
                            </p>
                            <p class="text-red" v-if="instancePriceDetails.discounts && instancePriceDetails.discounts.length">
                                -${{
									instancePriceDetails.discounts &&
                                    instancePriceDetails.discounts
                                        .reduce((acc, discount) => {
                                            return acc + discount.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) || (0).toFixed(2)
                                }}
                            </p>
                            <p v-if="instancePriceDetails.tax && instancePriceDetails.tax.length">
                                ${{
									instancePriceDetails.tax &&
                                    instancePriceDetails.tax
                                        .reduce((acc, tax) => {
                                            return acc + tax.price;
                                        }, 0)
                                        .toLocaleString("en-US", {
                                            useGrouping: true,
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }) || (0).toFixed(2)
                                }}
                            </p>

                            <p>
                                <span
                                    class="dF aC ml-3"
                                    style="height: 24px; justify-content: end"
                                >
                                    <a-form-item
                                        style="width: 160px; margin: 0 30px 0 0"
                                        has-feedback
                                        :validate-status="validCouponCode"
                                    >
                                        <a-input
                                            id="couponCode"
                                            v-model="couponCode"
                                            size="small"
                                        />
                                    </a-form-item>
                                    <span
                                        class="text-primary"
                                        @click="fetchAddOnPriceDetails"
                                        style="cursor: pointer"
                                    >
                                        Apply</span
                                    >
                                </span>
                            </p>
                            <p>
                                <b>
                                    ${{
                                        instancePriceDetails.total &&
                                        instancePriceDetails.total.toLocaleString(
                                            "en-US",
                                            {
                                                useGrouping: true,
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            }
                                        )
                                    }}
                                </b>
                            </p>
                            <p>{{ instancePriceDetails.currency || "USD" }}</p>
                        </a-col>
                        <a-col :span="$mq == 'sm' ? 2 : 1" />
                    </a-row>
                </div>
                <hr style="margin-left: -24px; margin-right: -24px" />
				<a-form-model-item
                    label="Select Payment Mode"
                    prop="paymentMode"
                >
					<a-radio-group v-model="paymentMode">
						<a-radio-button value="online">
							Online
						</a-radio-button>
						<a-radio-button value="offline">
							Offline
						</a-radio-button>
					</a-radio-group>
                </a-form-model-item>
				<div v-if="instance.paymentMode === 'online'">
					<a-form
	                    class="profile mt-5"
	                    v-if="paymentMethods.length"
	                >
	                    <a-row v-if="$mq != 'sm'">
	                        <a-col :span="$mq == 'sm' ? 2 : 2" />
	                        <a-col :span="$mq == 'sm' ? 8 : 8">
	                            <strong> Credit Card Number </strong>
	                        </a-col>
	                        <a-col :span="$mq == 'sm' ? 8 : 8">
	                            <strong>Cardholder Name </strong>
	                        </a-col>
	                        <a-col :span="4">
	                            <strong>Expiry Date </strong></a-col
	                        >
	                    </a-row>
	                    <hr
	                        v-if="$mq != 'sm'"
	                        style="margin-left: -24px; margin-right: -24px"
	                    />
	                    <div
	                        v-for="card in paymentMethods"
	                        :key="card.id"
	                    >
	                        <a-row>
	                            <a-col :span="$mq == 'sm' ? 2 : 1">
	                                <a-radio
	                                    :value="card.id"
	                                    @change="onChange"
	                                    :checked="card.id === selectedPaymentMethod"
	                                />
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 2 : 2">
	                                <img
	                                    v-if="
	                                        card &&
	                                        card.details &&
	                                        card.details.ccBrand === 'visa'
	                                    "
	                                    alt="Visa"
	                                    src="@/assets/visa.svg"
	                                    width="50px"
	                                />
	                                <img
	                                    v-else-if="
	                                        card &&
	                                        card.details &&
	                                        card.details.ccBrand === 'mc'
	                                    "
	                                    alt="MasterCard"
	                                    src="@/assets/mc.svg"
	                                    width="50px"
	                                />
	                                <img
	                                    v-else-if="
	                                        card &&
	                                        card.details &&
	                                        card.details.ccBrand === 'amex'
	                                    "
	                                    alt="American Express"
	                                    src="@/assets/amex.svg"
	                                    width="50px"
	                                />
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 6 : 6">
	                                {{ cardNumberDetails(card.details) }}
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 6 : 8">
	                                {{ card.details.cardName }}
	                            </a-col>
	                            <a-col :span="5">
	                                {{ card.details.expiry }}
	                            </a-col>
	                            <a-col :span="$mq == 'sm' ? 2 : 1" class="dF">
	                                <div
	                                    class="mr-4 edit-delete"
	                                    @click="editCard(card)"
	                                >
										<a-icon type="edit" />
	                                </div>
	                                <div
	                                    class="edit-delete"
	                                    @click="deleteCard(card)"
	                                    style="color: var(--danger)"
	                                >
										<a-icon type="delete" style="color:red" />
	                                </div>
	                            </a-col>
	                        </a-row>
	                        <hr
	                            style="margin-left: -24px; margin-right: -24px"
	                        />
	                    </div>
	                </a-form>
					<h5
	                    class="pl-3 pt-3"
	                    style="color: var(--orange); cursor: pointer"
	                    @click="resetPaymentMethodModalData, (paymentMethodModal.show = true)"
	                >
	                    <a-icon type="plus-circle" /> &nbsp;&nbsp;Add a payment
	                    method
	                </h5>
				</div>
                <div :class="$mq == 'sm' ? '' : 'dF'">
                    <div class="dF jE w-full m-3">
                        <a-button-group>
                            <a-button
                                @click="current--; couponCode = ''"
                                style="width: 100px; margin-right: 20px"
                                >BACK</a-button
                            >
                            <a-button
                                @click="addAddOns"
                                :loading="loadModal"
                                type="primary"
                                style="width: 120px"
                                >COMPLETE</a-button
                            >
                        </a-button-group>
                    </div>
                </div>
            </a-card>
        </div>
        <div v-else>
            <a-tabs size="large" default-active-key="1" v-model="activeTab">
                <div slot="tabBarExtraContent" style="margin-top: 10px">
                    <div class="dF aC" style="gap: 10px">
						<a-button v-if="selectedInvoiceIds.length" :loading="$store.state.downloadingInvoices" type="primary" @click="bulkPayment"
							ghost>Bulk Payment</a-button>
						<a-button v-if="selectedInvoiceIds.length" :loading="$store.state.downloadingInvoices" type="primary" @click="bulkDownload"
							ghost>Bulk Download</a-button>
						<a-button
							v-if="selectedInvoiceIds.length"
							:loading="loading"
							type="primary"
                            @click="bulkVoidInvoices"
                            ghost>Bulk Void</a-button
                        >
						<a-button
							v-if="selectedInvoiceIds.length"
							:loading="loading"
							type="primary"
                            @click="convertToOfflinePayment"
                            ghost>Change To Offline</a-button
                        >
						<a-button
							v-if="activeTab === '4'"
							type="primary"
                            @click="downloadSetupFees"
                            ghost>Download CSV</a-button
                        >
                        <a-button
                            v-if="!project.susspended"
                            @click="lockModal = true"
                            ><a-icon type="lock" />LOCK PROJECT</a-button
                        >
                        <a-button v-else @click="unlockModal = true"
                            ><a-icon type="unlock" />UNLOCK PROJECT</a-button
                        >
                        <a-button
                            @click="deleteModal = true"
                            type="danger"
                            ghost
                            ><a-icon type="delete" />DELETE PROJECT</a-button
                        >
                        <a-button
                            v-if="activeTab === '3'"
                            @click="openFeatureLogModal('training')"
                            type="primary"
                            ghost
                            ><a-icon type="plus" />ADD TRAINING</a-button
                        >
						<a-button
                            v-if="activeTab === '4'"
                            @click="openFeatureLogModal('setup')"
                            type="primary"
                            ghost
                            ><a-icon type="plus" />ADD SETUP FEE</a-button
                        >
                    </div>
                </div>
                <a-tab-pane key="1" tab="Overview">
					<div class="card-grid">
                        <a-card>
                            <h6>Current Plan</h6>
                            <h4>{{ project.package && project.package.name }}</h4>
							<div class="dF aC jSB">
								<div
                                    @click="changePlan"
                                    class="
                                        py-1
                                        px-2
                                        mt-5
                                        text-center
                                        gray-button
                                    "
                                >
                                    CHANGE OR UPDATE PLAN
                                </div>
								<div
                                    @click="addAddons"
                                    class="
                                        py-1
                                        px-2
                                        mt-5
                                        text-center
                                        gray-button
                                    "
                                >
                                    ADD ADDONS
                                </div>
							</div>
                        </a-card>
						<a-card>
							<h6>Current AddOns</h6>
							<ul>
								<li v-for="addOn in currentAddOns" :key="addOn.id">
									{{addOn.name}}:  {{ addOn.quantity === 1 ? '✅' : addOn.quantity }}
								</li>
							</ul>
						</a-card>
                        <a-card v-if="lastInvoice && lastInvoice.paymentMode !== 'offline'">
                            <h6>Will be automatically charged to:</h6>
                            <div style="min-height: 27px">
                                <h4
                                    v-if="
                                        currentPaymentMethod &&
                                        currentPaymentMethod.details
                                    "
                                >
                                    <img
                                        v-if="
                                            currentPaymentMethod.details
                                                .ccBrand === 'visa'
                                        "
                                        alt="Visa"
                                        src="@/assets/visa.svg"
                                        width="12%"
                                        height="12%"
                                    />
                                    <img
                                        v-else-if="
                                            currentPaymentMethod.details
                                                .ccBrand === 'mc'
                                        "
                                        alt="MasterCard"
                                        src="@/assets/mc.svg"
                                        width="12%"
                                        height="12%"
                                    />
                                    <img
                                        v-else-if="
                                            currentPaymentMethod.details
                                                .ccBrand === 'amex'
                                        "
                                        alt="American Express"
                                        src="@/assets/amex.svg"
                                        width="12%"
                                        height="12%"
                                    />
                                    ending
                                    {{
                                        currentPaymentMethod.details.cardNo.substr(
                                            -4
                                        )
                                    }}
                                </h4>
                            </div>
                            <div
                                @click="switchCardModal.visible = true; switchCardModal.card = currentPaymentMethod;"
                                class="
                                    py-1
                                    px-2
                                    mt-3
                                    text-center
                                    gray-button
                                "
                            >
                                CHANGE PAYMENT METHOD
                            </div>
                        </a-card>
                    </div>
					<div class="dF mt-3" style="gap: 20px">
	                    <div style="width: 67%">
	                        <a-form class="profile">
	                            <div>
	                                <a-card>
										<div class="dF aC jSB">
											<h4 class="text-dark">
		                                        Project Profile
		                                    </h4>
											<div style="font-weight:bold; background:var(--light-divider); border-radius:40px; font-size: 14px;" class="px-3 py-2">{{supportRequestType}}</div>
										</div>
	                                    <hr
	                                        style="
	                                            margin-left: -24px;
	                                            margin-right: -24px;
	                                        "
	                                    />
	                                    <a-form-model
	                                        ref="ruleForm"
	                                        :model="instance"
	                                        :rules="rules"
	                                    >
	                                        <a-row :gutter="16">
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    label="Project ID"
	                                                >
	                                                    <a-input
	                                                        v-model="
	                                                            instance.readableId
	                                                        "
	                                                        placeholder="Project ID"
	                                                        size="large"
	                                                        disabled
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    ref="name"
	                                                    label="Project Name"
	                                                    prop="name"
	                                                >
	                                                    <a-input
	                                                        v-model="instance.name"
	                                                        placeholder="Project Name"
	                                                        size="large"
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
												<a-col :span="8">
	                                                <a-form-model-item
	                                                    ref="marketingName"
	                                                    prop="marketingName"
	                                                >
														<template slot="label">
															Project Marketing Name
															<a-tooltip overlayClassName="change-tooltip-color" title="Fill in this field if you need a forward-facing name that end users will see for your project.">
																<a-icon type="question-circle" style="font-size: 14px" />
															</a-tooltip>
														</template>
	                                                    <a-input
	                                                        v-model="instance.marketingName"
	                                                        placeholder="Project Marketing Name"
	                                                        size="large"
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
												<a-col :span="8" v-if="instance.partner">
	                                                <a-form-model-item label="Partner" >
	                                                    <a-input
	                                                        :value="
	                                                            instance.partner.companyName
	                                                        "
	                                                        placeholder="Partner"
	                                                        size="large"
	                                                        disabled
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                        </a-row>
	                                        <a-row :gutter="16">
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    ref="projectType"
	                                                    label="Project Type"
	                                                    prop="projectType"
	                                                >
	                                                    <a-input
	                                                        :value="
	                                                            instance.projectType
	                                                        "
	                                                        placeholder="Project Type"
	                                                        size="large"
	                                                        disabled
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    ref="productType"
	                                                    label="Product Type"
	                                                    prop="productType"
	                                                >
	                                                    <a-input
	                                                        :value="
	                                                            instance.productType ===
	                                                            'lowrise'
	                                                                ? 'Low Rise'
	                                                                : 'High Rise'
	                                                        "
	                                                        placeholder="Product Type"
	                                                        size="large"
	                                                        disabled
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
												<a-col :span="8">
	                                                <a-form-model-item
	                                                    ref="status"
	                                                    label="Project Status"
	                                                    prop="status"
	                                                >
	                                                    <a-input
	                                                        :value="projectStatus"
	                                                        placeholder="Project Status"
	                                                        size="large"
	                                                        disabled
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                        </a-row>
	                                        <a-row :gutter="16">
												<a-col :span="8">
			                                        <a-form-model-item
			                                            required
			                                            prop="billingName"
			                                            ref="billingName"
			                                        >
														<template slot="label">
															Billing Name
															<a-tooltip overlayClassName="change-tooltip-color" title="The 'Billing Name' is what will appear on the invoice.">
																<a-icon type="question-circle" style="font-size: 14px" />
															</a-tooltip>
														</template>
			                                            <a-input
			                                                v-model="instance.billingName"
			                                                placeholder="Billing Name"
			                                                size="large"
			                                                required
			                                            >
			                                            </a-input>
			                                        </a-form-model-item>
			                                    </a-col>
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    label="Address"
	                                                    prop="address"
	                                                >
	                                                    <a-input
	                                                        v-model="
	                                                            instance.address
	                                                        "
	                                                        placeholder="Address"
	                                                        size="large"
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    label="City"
	                                                    prop="city"
	                                                >
	                                                    <a-input
	                                                        v-model="instance.city"
	                                                        placeholder="Enter a city name"
	                                                        size="large"
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                        </a-row>
	                                        <a-row :gutter="16">
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    prop="region"
	                                                    label="Province/State"
	                                                >
	                                                    <a-input
	                                                        v-model="
	                                                            instance.region
	                                                        "
	                                                        placeholder="Enter a Province / State name"
	                                                        size="large"
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    prop="postalCode"
	                                                    label="Zip/Postal Code"
	                                                >
	                                                    <a-input
	                                                        v-model="
	                                                            instance.postalCode
	                                                        "
	                                                        placeholder="Postal Code"
	                                                        size="large"
	                                                    >
	                                                    </a-input>
	                                                </a-form-model-item>
	                                            </a-col>
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    prop="country"
	                                                    label="Country"
	                                                >
	                                                    <a-select
	                                                        v-model="
	                                                            instance.country
	                                                        "
	                                                        placeholder="Country"
	                                                        size="large"
	                                                    >
	                                                        <a-select-option
	                                                            value="US"
	                                                            >United
	                                                            States</a-select-option
	                                                        >
	                                                        <a-select-option
	                                                            value="CA"
	                                                            >Canada</a-select-option
	                                                        >
	                                                    </a-select>
	                                                </a-form-model-item>
	                                            </a-col>
	                                        </a-row>
											<a-row :gutter="16">
	                                            <a-col :span="8">
	                                                <a-form-model-item
	                                                    prop="promoCode"
	                                                    label="Promo Code"
	                                                >
														<a-select
	                                                        v-model="instance.promoCode"
	                                                        placeholder="Select Promo Code"
															option-label-prop="label"
	                                                        size="large"
															allow-clear
	                                                    >
	                                                        <a-select-option v-for="promoCode in promoCodes" :key="promoCode.id" :value="promoCode.code" :label="promoCode.code" :disabled="promoCode.isExpired || !promoCode.published_at">
																<span>
																	{{ promoCode.code }}
																	<a-tag class="ml-2" color="#dc3545" v-if="promoCode.isExpired"> Expired </a-tag>
																	<a-tag class="ml-2" color="#28c791" v-else-if="promoCode.published_at"> Active </a-tag>
																	<a-tag class="ml-2" color="#ffc107" v-else> Draft </a-tag>
																</span>
																<br>
																<span class="text-sm" :style="`color: ${ promoCode.isExpired || !promoCode.published_at ? 'lightgray' : 'gray'}`">
																	{{ promoCode.name }} ({{ promoCode.type === "percentage" ? promoCode.value + " %" : promoCode.type === "amount" ? "$ " + promoCode.value : promoCode.value }})
																</span>
															</a-select-option>
	                                                    </a-select>
	                                                </a-form-model-item>
	                                            </a-col>
											</a-row>
	                                        <a-row :gutter="16">
	                                            <a-col :span="24">
	                                                <a-divider orientation="left"
	                                                    >Link Other Child
	                                                    Projects</a-divider
	                                                >
	                                                <a-form-model-item
	                                                    label="Link child projects to the parent project"
	                                                    class="dF aC"
	                                                >
	                                                    <a-select
	                                                        mode="multiple"
	                                                        size="large"
	                                                        placeholder="Please select"
	                                                        v-model="children"
	                                                        class="ml-3"
	                                                        style="width: 500px"
	                                                    >
	                                                        <a-select-option
	                                                            v-for="(
	                                                                inst, instI
	                                                            ) in instances"
	                                                            :key="
	                                                                (
	                                                                    instI + 9
	                                                                ).toString(36) +
	                                                                instI
	                                                            "
	                                                            :value="inst.id"
	                                                        >
	                                                            {{ inst.name }}
	                                                        </a-select-option>
	                                                    </a-select>
	                                                </a-form-model-item>
	                                            </a-col>
	                                        </a-row>
	                                    </a-form-model>

										<hr style="margin-left: -24px; margin-right: -24px" />
										<h6 class="mb-4 mt-4 text-dark">Currency Settings</h6>
										<hr style="margin-left: -24px; margin-right: -24px" />

										<a-row :gutter="16">
											<a-col :span="12">
												<a-form-model-item label="Currency">
													<a-select
														v-model="instance.currency"
														size="large"
													>
														<a-select-option value="USD"
															>United States Dollar
															($)</a-select-option
														>
														<a-select-option value="CAD"
															>Canadian Dollar
															(CAD)</a-select-option
														>
													</a-select>
												</a-form-model-item>
											</a-col>
										</a-row>

										<hr style="margin-left: -24px; margin-right: -24px" />
										<h6 class="mb-4 mt-4 text-dark">Measurement Settings</h6>
										<hr style="margin-left: -24px; margin-right: -24px" />
										<a-form-model ref="measurementSettings" :model="measurementSettings">
											<a-row :gutter="16">
												<a-col :span="12" v-if="instance.productType === 'lowrise'
													">
													<a-form-model-item>
														<div slot="label" class="dF aC">
															<div class="mr-1">
																Lot Frontage Size
															</div>
															<a-tooltip overlayClassName="change-tooltip-color">
																<template slot="title">
																	This will allow you to set your
																	site plan lot frontage to either
																	feet or metres.
																</template>
																<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
															</a-tooltip>
														</div>
														<a-select v-model="measurementSettings.frontageSize" size="large">
															<a-select-option value="ft">Feet (ft)</a-select-option>
															<a-select-option value="m">Metre (m)</a-select-option>
														</a-select>
													</a-form-model-item>
												</a-col>
												<a-col :span="12">
													<a-form-model-item>
														<div slot="label" class="dF aC">
															<div class="mr-1">
																Home and Unit Measurements
															</div>
															<a-tooltip overlayClassName="change-tooltip-color">
																<template slot="title">
																	This will determine if your
																	model will be shown as feet or
																	metres.
																</template>
																<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
															</a-tooltip>
														</div>
														<a-select v-model="measurementSettings.generalMeasurement" size="large">
															<a-select-option value="ft">Feet (ft)</a-select-option>
															<a-select-option value="m">Metre (m)</a-select-option>
														</a-select>
													</a-form-model-item>
												</a-col>
											</a-row>
										</a-form-model>
										<div v-if="instanceAPIKey">
											<hr style="margin-left: -24px; margin-right: -24px" />
											<h6 class="mb-4 mt-4 text-dark">API security</h6>
											<hr style="margin-left: -24px; margin-right: -24px" />

											<a-form-model>
												<a-row :gutter="16">
													<a-col :span="12">
														<a-form-model-item>
															<template slot="label">
																API Key
																<a-tooltip placement="right" overlayClassName="change-tooltip-color">
																	<template slot="title">
																		API Key.
																	</template>
																	<a-icon type="question-circle"
																		style="font-size: 14px; color: black; margin-left: 2px;" />
																</a-tooltip>
															</template>
															<a-input :value="showAPIKey ? instanceAPIKey : '********************' + instanceAPIKey.substring(
																20
															)" size="large" disabled>
																<div slot="addonBefore" class="dF aC" style="gap: 5px">
																	<a-icon type="copy" @click="copy(instanceAPIKey)" />
																</div>
																<div slot="addonAfter" class="dF aC" style="gap: 5px">
																	<a-icon :type="showAPIKey ? 'eye-invisible' : 'eye'"
																		@click="showAPIKey = !showAPIKey" />
																</div>
															</a-input>
														</a-form-model-item>
													</a-col>
												</a-row>
											</a-form-model>
										</div>

	                                    <div class="w-full mt-5">
	                                        <div class="pull-right">
	                                            <a-button
	                                                class="mr-3"
	                                                @click="goBack"
	                                                >CANCEL</a-button
	                                            >
	                                            <a-button
	                                                type="primary"
	                                                @click="submit"
													:loading="loading"
	                                                >SAVE</a-button
	                                            >
	                                        </div>
	                                    </div>
	                                </a-card>
	                            </div>
	                        </a-form>
	                    </div>
	                    <div style="width: 33%">
	                        <a-card style="max-height: 150px">
	                            <div
	                                class="dF aC"
	                                style="
	                                    gap: 10px;
	                                    justify-content: space-between;
	                                    font-size: 18px;
	                                "
	                            >
	                                <h5>Members</h5>
	                                <!-- <a
	                                    @click.prevent="viewAll"
	                                    href="javascript:;"
	                                    class="text-primary"
	                                    >View All</a
	                                > -->
	                            </div>
	                        </a-card>
	                        <a-table
	                            :rowKey="(e) => e.id"
	                            :columns="userColumns"
	                            :dataSource="users"
	                            :pagination="false"
	                            :loading="loading"
	                            class="list-view"
	                        >
	                            <div slot="name" slot-scope="user">
	                                <span>{{
	                                    user.firstName + " " + user.lastName
	                                }}</span>
	                            </div>
	                            <div slot="role" slot-scope="user">
	                                <span>{{ user.role }}</span>
	                            </div>
	                            <div
	                                slot="actions"
	                                slot-scope="user"
	                                class="dF"
	                                style="justify-content: flex-end"
	                            >
	                                <a-popover
	                                    trigger="hover"
	                                    placement="bottomRight"
	                                    overlayClassName="popoverStyle"
	                                >
	                                    <div slot="content">
	                                        <div
	                                            v-if="user.role === 'Project Owner' || user.role === 'Project & Billing Owner'"
	                                            class="popoverContent dF aC"
	                                            @click="changeProjectOwnerModal = true"
	                                        >
	                                            Update Project Owner
	                                        </div>
											<div
	                                            v-if="user.role === 'Billing Owner' || user.role === 'Project & Billing Owner'"
	                                            class="popoverContent dF aC"
	                                            @click="changeBillingOwnerModal = true"
	                                        >
	                                            Update Billing Owner
	                                        </div>
	                                        <div
	                                            class="popoverContent dF aC"
	                                            @click="editUser(user)"
	                                        >
	                                            Edit User
	                                        </div>
	                                        <div
	                                            class="popoverContent dF aC"
	                                            @click="
	                                                selectedUser = user;
	                                                resetPasswordModal = true;
	                                            "
	                                        >
	                                            Reset Password Access
	                                        </div>
	                                    </div>
	                                    <div class="more-option-icon mr-4">
	                                        <a-icon
	                                            style="line-height: 40px"
	                                            type="more"
	                                        />
	                                    </div>
	                                </a-popover>
	                            </div>
	                        </a-table>
	                    </div>
					</div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="Invoices">
                    <div v-if="editingInvoice">
						<div class="dF aC" style="justify-content: space-between">
	                        <div
	                            class="dF aC"
	                            style="
	                                gap: 10px;
	                                font-weight: bold;
	                                cursor: pointer;
	                            "
	                            @click="
	                                editingInvoice = false;
	                                selectedInvoice = {};
	                            "
	                        >
	                            <a-icon type="arrow-left" /><span
	                                >Back to invoice list</span
	                            >
	                        </div>
							<div class="dF pull-right" style="gap: 20px">
			                    <div
			                        @click="invoiceToDownload = selectedInvoice"
			                        class="dF aC"
			                        style="color: var(--orange); cursor: pointer"
			                    >
			                        <a-icon
			                            class="mr-1"
			                            type="download"
			                            style="font-size: 24px"
			                        />
			                        PDF
			                    </div>
			                </div>
						</div>
                        <div class="mt-3 mb-3">
                            <div class="invoice-card-grid">
                                <a-card>
                                    <a-row :gutter="16">
                                        <a-col :span="8">
                                            <h5>
                                                Invoice #{{
                                                    selectedInvoice.invoiceNumber
                                                }}
                                            </h5>
                                        </a-col>
                                        <a-col :span="8">
                                            <div
                                                class="py-1 px-3 text-center"
                                                :style="
                                                    selectedInvoice.paid
                                                        ? 'background-color:#28C791'
                                                        : 'background-color:#FD647C'
                                                "
                                                style="
                                                    color: #fff;
                                                    border-radius: 4px;
                                                    width: fit-content;
                                                "
                                            >
                                                {{
                                                    selectedInvoice.paid
                                                        ? "PAID"
                                                        : "UNPAID"
                                                }}
                                            </div>
                                        </a-col>
                                        <a-col
                                            :span="8"
                                            v-if="!selectedInvoice.paid"
                                        >
											<div
                                                @click="
                                                    makePayment(selectedInvoice)
                                                "
                                                class="py-1 px-2 text-center"
                                                style="
                                                    border-radius: 4px;
                                                    width: 100%;
                                                    background-color: #fff;
                                                    border: 1px solid
                                                        var(--orange);
                                                    color: var(--orange);
                                                    cursor: pointer;
                                                "
                                            >
                                                MAKE A PAYMENT
                                            </div>
                                        </a-col>
                                        <a-col :span="8" v-else>
                                            <div
												@click="() => {
													if(selectedInvoice.refundStatus !== 'full') {
														refundModal = true;
	                                                    partialRefund = false;
	                                                    refundAmount = 0;
	                                                    refundReason = '';
													}
												}"
                                                class="py-1 px-3 text-center"
                                                style="
                                                    border-radius: 4px;
                                                    width: 100%;
                                                    background-color: #fff;
                                                    border: 1px solid
                                                        var(--orange);
                                                    color: var(--orange);
                                                    cursor: pointer;
                                                "
												:style="selectedInvoice.refundStatus === 'full' ? 'background-color:var(--light-gray); color:var(--med-gray); cursor:not-allowed' : ''"
                                            >
                                                FULL REFUND
                                            </div>
                                        </a-col>
                                    </a-row>
                                    <a-row :gutter="16" class="mt-2">
                                        <a-col :span="16">
                                            <div class="dF aC jSB">
                                                <h4>
                                                    ${{
                                                        selectedInvoice.total
                                                    }}
                                                    ({{
                                                        selectedInvoice.currency ||
                                                        "USD"
                                                    }})
                                                </h4>

												<div v-if="!selectedInvoice.paid && selectedInvoice.paymentMode === 'offline' && remainingAmount" class="dF aC">
													<h6>
														<span>Remaining Amount: </span>
	                                                    ${{
	                                                        remainingAmount
	                                                    }}
	                                                    ({{
	                                                        selectedInvoice.currency ||
	                                                        "USD"
	                                                    }})
	                                                </h6>
												</div>
                                            </div>
                                        </a-col>
                                        <a-col :span="8">
                                            <div
                                                v-if="selectedInvoice.paid"
                                                @click="() => {
													if(selectedInvoice.refundStatus !== 'full') {
														refundModal = true;
	                                                    partialRefund = true;
	                                                    refundAmount = 0;
	                                                    refundReason = '';
													}
												}"
                                                class="py-1 px-3 text-center"
                                                style="
                                                    border-radius: 4px;
                                                    width: 100%;
                                                    background-color: #fff;
                                                    border: 1px solid
                                                        var(--orange);
                                                    color: var(--orange);
                                                    cursor: pointer;
                                                "
												:style="selectedInvoice.refundStatus === 'full' ? 'background-color:var(--light-gray); color:var(--med-gray); cursor:not-allowed' : ''"
                                            >
                                                PARTIAL REFUND
                                            </div>
                                        </a-col>
                                    </a-row>
                                    <span
                                        class="w-full dF pl-2"
                                        style="background-color: lightpink"
                                        v-if="!selectedInvoice.paid && selectedInvoice.paymentMode !== 'offline'"
                                        >{{
                                            selectedInvoice.error ||
                                            "Your card has been declined"
                                        }}</span
                                    >
                                    <a-row :gutter="16" class="mt-2">
                                        <a-col :span="8">
                                            <h5>Billing Period</h5>
                                            <span style="color: #9ea0a5">{{!selectedInvoice.validUntil ? "N/A" :
                                                `${getDate(
                                                    selectedInvoice.createdAt
                                                )} - ${getDate(
                                                    +selectedInvoice.validUntil
                                                )}`
                                            }}</span>
                                        </a-col>
                                        <a-col :span="8">
                                            <h5>Next Invoice Date</h5>
                                            <span style="color: #9ea0a5">{{
                                                !selectedInvoice.validUntil ? "N/A" : getDate(+selectedInvoice.validUntil)
                                            }}</span>
                                        </a-col>
                                        <a-col
                                            :span="8"
                                            v-if="
                                                selectedInvoice.refundStatus ===
                                                'partial'
                                            "
                                        >
                                            <h5>Refund Amount</h5>
                                            <span style="color: #9ea0a5"
                                                >{{
                                                    calculateTotalRefund(
                                                        selectedInvoice.refunds
                                                    )
                                                }}
                                                ({{
                                                    selectedInvoice.currency ||
                                                    "USD"
                                                }})</span
                                            >
                                        </a-col>
                                        <a-col
                                            :span="8"
                                            v-if="
                                                selectedInvoice.refundStatus ===
                                                'full'
                                            "
                                        >
                                            <h5>Refund Amount</h5>
                                            <span style="color: #9ea0a5"
                                                >${{
                                                    selectedInvoice.total
                                                }}
                                                ({{
                                                    selectedInvoice.currency ||
                                                    "USD"
                                                }})</span
                                            >
                                        </a-col>
                                    </a-row>
                                </a-card>
                                <a-card>
                                    <h6>Current Plan</h6>
                                    <h4>
                                        {{
                                            selectedInvoice.details && selectedInvoice.details.package && selectedInvoice.details.package.name
                                        }}
                                    </h4>
                                    <div class="dF aC jSB">
										<div
		                                    @click="changePlan"
		                                    class="
		                                        py-1
		                                        px-2
		                                        mt-5
		                                        text-center
		                                        gray-button
		                                    "
		                                >
		                                    CHANGE OR UPDATE PLAN
		                                </div>
										<div
		                                    @click="addAddons"
		                                    class="
		                                        py-1
		                                        px-2
		                                        mt-5
		                                        text-center
		                                        gray-button
		                                    "
		                                >
		                                    ADD ADDONS
		                                </div>
									</div>
                                </a-card>
                                <a-card v-if="selectedInvoice.paymentMode !== 'offline'">
                                    <h6>Will be automatically charged to:</h6>
                                    <h4
                                        v-if="
                                            currentPaymentMethod &&
                                            currentPaymentMethod.details
                                        "
                                    >
                                        <img
                                            v-if="
                                                currentPaymentMethod.details
                                                    .ccBrand === 'visa'
                                            "
                                            alt="Visa"
                                            src="@/assets/visa.svg"
                                            width="12%"
                                            height="12%"
                                        />
                                        <img
                                            v-else-if="
                                                currentPaymentMethod.details
                                                    .ccBrand === 'mc'
                                            "
                                            alt="MasterCard"
                                            src="@/assets/mc.svg"
                                            width="12%"
                                            height="12%"
                                        />
                                        <img
                                            v-else-if="
                                                currentPaymentMethod.details
                                                    .ccBrand === 'amex'
                                            "
                                            alt="American Express"
                                            src="@/assets/amex.svg"
                                            width="12%"
                                            height="12%"
                                        />
                                        ending
                                        {{
                                            currentPaymentMethod.details.cardNo.substr(
                                                -4
                                            )
                                        }}
                                    </h4>
                                    <div
                                        @click="switchCardModal.visible = true; switchCardModal.card = currentPaymentMethod;"
                                        class="
                                            py-1
                                            px-2
                                            mt-5
                                            text-center
                                            gray-button
                                        "
                                    >
                                        CHANGE PAYMENT METHOD
                                    </div>
                                </a-card>
                            </div>
                        </div>

                        <div
                            class="profile mt-5"
                        >
                            <a-card>
                                <h4>Summary</h4>
                                <hr
                                    class="mt-3"
                                    v-if="$mq != 'sm'"
                                    style="
                                        margin-left: -24px;
                                        margin-right: -24px;
                                    "
                                />
                                <a-row>
                                    <a-col :span="$mq == 'sm' ? 12 : 16">
                                        <b>Package options </b>
                                    </a-col>
                                    <a-col
                                        :span="$mq == 'sm' ? 4 : 6"
                                        style="text-align: right"
                                    >
                                        <b>Subtotal</b>
                                    </a-col>
                                </a-row>
                                <hr
                                    v-if="$mq != 'sm'"
                                    style="
                                        margin-left: -24px;
                                        margin-right: -24px;
                                    "
                                />
                                <a-row v-if="selectedInvoice.details && selectedInvoice.details.package">
                                    <a-col :span="$mq == 'sm' ? 12 : 16">
                                        <div class="text-primary">
                                            RECURRING FEES
                                        </div>
                                        {{
                                            selectedInvoice.details.package
                                                .name
                                        }}
                                    </a-col>
                                    <a-col
                                        :span="$mq == 'sm' ? 4 : 6"
                                        style="text-align: right"
                                        class="text-black"
                                    >
                                        <b
                                            >${{
                                                selectedInvoice.details.package.price
                                            }}</b
                                        >
                                    </a-col>
                                </a-row>
                                <hr
                                    v-if="$mq != 'sm' && selectedInvoice.details && selectedInvoice.details.package"
                                    style="
                                        margin-left: -24px;
                                        margin-right: -24px;
                                    "
                                />
                                <div
                                    v-for="(
                                        additional, index
                                    ) in (selectedInvoice.details && selectedInvoice.details.selectedPackageExtras || [])"
                                    :key="index"
                                >
                                    <a-row>
                                        <a-col :span="$mq == 'sm' ? 12 : 16">
			                                <span
			                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
			                                >
			                                    <div class="text-primary">
			                                        ADD ON FEES
			                                    </div>
			                                    {{ additional.name }}
			                                </span>
                                            <span
                                                v-else-if="
                                                    additional.name ===
                                                    'Onboarding'
                                                "
                                            >
                                                <div class="text-primary">
                                                    ONE TIME FEES
                                                    <a-tooltip overlayClassName="change-tooltip-color">
                                                        <template slot="title">
                                                            One time fee
                                                            (Applicable if you
                                                            are a first time
                                                            user of Bildhive)
                                                        </template>
                                                        <a-icon
                                                            type="question-circle"
                                                            style="
                                                                font-size: 12px;
                                                                color: black;
                                                                margin-left: 5px;
                                                            "
                                                        />
                                                    </a-tooltip>
                                                </div>
                                                {{ additional.name }}
                                            </span>
                                            <span
                                                v-else-if="
                                                    additional.name ===
                                                    'Lot/Door Fee'
                                                "
                                            >
                                                <div class="text-primary">
                                                    <a-popover
                                                        placement="right"
                                                    >
                                                        <template slot="title">
                                                            <h3
                                                                class="
                                                                    text-primary
                                                                    text-center
                                                                "
                                                            >
                                                                {{
                                                                    instance.productType ==
                                                                    "highrise"
                                                                        ? "DOOR FEES"
                                                                        : "LOT FEES"
                                                                }}
                                                            </h3>
                                                        </template>
                                                        <template
                                                            slot="content"
                                                        >
                                                            <div
                                                                style="
                                                                    text-align: justify;
                                                                    width: 400px;
                                                                "
                                                            >
                                                                Includes 50
                                                                lots/doors per
                                                                development
                                                                project.
                                                                Additional
                                                                lots/doors are
                                                                charged on a per
                                                                door basis
                                                                starting at $30
                                                                per door.
                                                            </div>
                                                            <div
                                                                class="
                                                                    modal-list
                                                                    d-flex
                                                                    my-2
                                                                "
                                                            >
                                                                <ul class="p-0">
                                                                    <li
                                                                        class="
                                                                            modal-list-title
                                                                        "
                                                                    >
                                                                        Door Fee
                                                                    </li>
                                                                    <li>
                                                                        First 50
                                                                    </li>
                                                                    <li>
                                                                        51 – 100
                                                                    </li>
                                                                    <li>
                                                                        101 –
                                                                        150
                                                                    </li>
                                                                    <li>
                                                                        151 –
                                                                        200
                                                                    </li>
                                                                    <li>
                                                                        201+
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li
                                                                        class="
                                                                            modal-list-title
                                                                        "
                                                                    >
                                                                        Billed
                                                                        at
                                                                    </li>
                                                                    <li>$0</li>
                                                                    <li>$30</li>
                                                                    <li>$25</li>
                                                                    <li>$20</li>
                                                                    <li>$15</li>
                                                                </ul>
                                                            </div>
                                                            <div
                                                                style="
                                                                    text-align: justify;
                                                                    width: 400px;
                                                                "
                                                            >
                                                                Each project
                                                                subscription is
                                                                inclusive of 50
                                                                lots/doors.
                                                                Thereafter, a
                                                                per door charge
                                                                applies. The
                                                                charge is based
                                                                on the number of
                                                                lots/doors to be
                                                                processed and
                                                                supported in
                                                                your development
                                                                project. The
                                                                Door fee is a
                                                                one-time fee
                                                                that is over and
                                                                above your
                                                                monthly
                                                                subscription fee
                                                                and it is
                                                                charged upon
                                                                initial project
                                                                set up.
																Note: Currency rates apply.
                                                            </div>
                                                        </template>
                                                        {{
                                                            project.productType ==
                                                            "highrise"
                                                                ? "DOOR"
                                                                : "LOT"
                                                        }}
                                                        FEES
                                                        <a-icon
                                                            type="question-circle"
                                                            style="
                                                                font-size: 12px;
                                                                color: black;
                                                                margin-left: 5px;
                                                            "
                                                        />
                                                    </a-popover>
                                                </div>
                                                {{
                                                    project.productType ==
                                                    "highrise"
                                                        ? `${additional.quantity} doors`
                                                        : `${additional.quantity} lots`
                                                }}
                                                total
                                            </span>
                                            <span
                                                v-else-if="
                                                    additional.name ===
                                                    'Training Hours'
                                                "
                                            >
                                                <div class="text-primary">
                                                    TRAINING HOUR BLOCKS
                                                    <a-tooltip overlayClassName="change-tooltip-color">
                                                        <template slot="title">
                                                            Additional training
                                                            hours are charged in
                                                            4 hour blocks @
                                                            $500/block.
															<br/>
															Note: Currency rates apply.
                                                        </template>
                                                        <a-icon
                                                            type="question-circle"
                                                            style="
                                                                font-size: 12px;
                                                                color: black;
                                                                margin-left: 5px;
                                                            "
                                                        />
                                                    </a-tooltip>
                                                </div>
                                                {{ additional.quantity }}
                                                Blocks ({{ additional.quantity * 4 }} Hours)
                                            </span>
											<span
			                                    v-else-if="
			                                        additional.name === 'Email sends'
			                                    "
			                                >
			                                    <div class="text-primary tT">
			                                        {{ additional.name }}
			                                    </div>
			                                    {{ additional.quantity }} Emails
			                                </span>
											<span
			                                    v-else-if="additional.name === 'SMS'"
			                                >
			                                    <div class="text-primary">
			                                        ADD ON FEES
			                                    </div>
			                                    SMS Profile Setup
			                                </span>
											<span
			                                    v-else
			                                >
			                                    <div class="text-primary tT">
			                                        {{ additional.name }}
			                                    </div>
			                                    {{ additional.quantity }}
			                                </span>
                                        </a-col>
                                        <a-col
                                            :span="$mq == 'sm' ? 4 : 6"
                                            style="text-align: right"
                                            class="text-black"
                                        >
                                            <b
                                                >${{
                                                    additional.price &&
                                                    additional.price.toLocaleString(
                                                        "en-US",
                                                        {
                                                            useGrouping: true,
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    )
                                                }}</b
                                            >
                                        </a-col>
                                    </a-row>
                                    <hr
                                        v-if="$mq != 'sm'"
                                        style="
                                            margin-left: -24px;
                                            margin-right: -24px;
                                        "
                                    />
                                </div>
								<div
									v-if="selectedInvoice.details && selectedInvoice.details.usageDetails"
			                    >
									<a-row>
			                            <a-col :span="$mq == 'sm' ? 12 : 16">
			                                <span>
			                                    <div class="text-primary">
			                                        USAGE FEES
			                                    </div>
			                                    {{ selectedInvoice.details.usageDetails.name }}
			                                </span>
										</a-col>
										<a-col
			                                :span="$mq == 'sm' ? 4 : 6"
			                                style="text-align: right"
			                                class="text-black"
			                            >
			                                <b>${{ selectedInvoice.details.usageDetails.price && selectedInvoice.details.usageDetails.price.toLocaleString("en-US", {
													useGrouping: true,
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}) || (0).toFixed(2) }}</b>
			                            </a-col>
									</a-row>
									<hr
			                            v-if="$mq != 'sm'"
			                            style="margin-left: -24px; margin-right: -24px"
			                        />
								</div>
                                <a-row>
                                    <a-col :span="$mq == 'sm' ? 6 : 2" />
                                    <a-col
                                        :span="$mq == 'sm' ? 6 : 14"
                                        style="text-align: right"
                                    >
                                        <p>Subtotal before tax</p>
                                        <p v-if="selectedInvoice.details && selectedInvoice.details.deductions && selectedInvoice.details.deductions.length">Deductions <span>({{ selectedInvoice.details.deductions.map(d => d.name).join(", ") }})</span></p>
										<p v-if="selectedInvoice.details && selectedInvoice.details.appliedDiscounts && selectedInvoice.details.appliedDiscounts.length">Discount <span>({{ selectedInvoice.details.appliedDiscounts.map(d => d.name).join(", ") }})</span></p>
										<p v-if="selectedInvoice.details && selectedInvoice.details.taxes && selectedInvoice.details.taxes.length">Tax <span>({{ selectedInvoice.details.taxes.map(t => t.name).join(", ") }})</span></p>
                                        <p v-if="selectedInvoice.promoCode">Promo Code</p>
                                        <p><b>Total Amount</b></p>
                                        <p>Currency</p>
                                    </a-col>
                                    <a-col
                                        :span="$mq == 'sm' ? 4 : 6"
                                        style="text-align: right"
                                    >
                                        <p>
                                            ${{
                                                selectedInvoice.subTotal &&
                                                selectedInvoice.subTotal.toLocaleString(
                                                    "en-US",
                                                    {
                                                        useGrouping: true,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                ) || (0).toFixed(2)
                                            }}
                                        </p>
                                        <p class="text-red" v-if="selectedInvoice.details && selectedInvoice.details.deductions && selectedInvoice.details.deductions.length ">
                                            -${{
			                                    selectedInvoice.details && selectedInvoice.details.deductions && selectedInvoice.details.deductions
			                                        .reduce((acc, discount) => {
			                                            return acc + discount.price;
			                                        }, 0)
			                                        .toFixed(2) || (0).toFixed(2)
			                                }}
			                            </p>
                                        <p class="text-red" v-if="selectedInvoice.details && selectedInvoice.details.appliedDiscounts && selectedInvoice.details.appliedDiscounts.length">
                                            -${{
                                                selectedInvoice.details && selectedInvoice.details.appliedDiscounts && selectedInvoice.details.appliedDiscounts
                                                    .reduce((acc, discount) => {
                                                        return (
                                                            acc + discount.price
                                                        );
                                                    }, 0)
                                                    .toLocaleString("en-US", {
                                                        useGrouping: true,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0).toFixed(2)
                                            }}
                                        </p>
                                        <p v-if="selectedInvoice.details && selectedInvoice.details.taxes && selectedInvoice.details.taxes.length">
                                            ${{
                                                selectedInvoice.details &&
												selectedInvoice.details.taxes && selectedInvoice.details.taxes
                                                    .reduce((acc, tax) => {
                                                        return acc + tax.price;
                                                    }, 0)
                                                    .toLocaleString("en-US", {
                                                        useGrouping: true,
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }) || (0).toFixed(2)
                                            }}
                                        </p>
										<p>
											<a-tag v-if="selectedInvoice.promoCode" class="m-0">{{selectedInvoice.promoCode}}</a-tag>
										</p>
                                        <p>
                                            <b>
                                                ${{
                                                    selectedInvoice.total &&
                                                    selectedInvoice.total.toLocaleString(
                                                        "en-US",
                                                        {
                                                            useGrouping: true,
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || (0).toFixed(2)
                                                }}
                                            </b>
                                        </p>
                                        <p>
                                            {{
                                                selectedInvoice.currency ||
                                                "USD"
                                            }}
                                        </p>
                                    </a-col>
                                </a-row>
                            </a-card>
                        </div>

						<div class="profile mt-5" v-if="selectedInvoice.payments && selectedInvoice.payments.length">
							<a-card>
			                    <h4>Invoice Offline Payments</h4>
								<hr
			                        class="mt-3"
			                        v-if="$mq != 'sm'"
			                        style="margin-left: -24px; margin-right: -24px"
			                    />
								<a-table
			                        :rowKey="(e, i) => e.id"
			                        :columns="paymentsColumns"
			                        :dataSource="selectedInvoice.payments"
			                        class="list-view"
									:loading="loading"
			                    >
			                        <div slot="amount" slot-scope="obj">
			                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
											useGrouping: false,
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}) || 0 }} ({{ selectedInvoice.currency || "USD"}})
			                        </div>
			                        <div slot="paymentDate" slot-scope="obj">
			                            {{
			                                getDateTime(obj.paymentDate)
			                            }}
			                        </div>
									<div slot="paymentMode" slot-scope="obj">
			                            {{ obj.paymentMode === 'check' ? 'Check' :  obj.paymentMode === 'bank_transfer' ? 'Bank Transfer' : 'Cash'  }}
			                        </div>
									<div slot="note" slot-scope="obj">
			                            {{ obj.note  }}
			                        </div>
			                        <div
			                            slot="actions"
			                            slot-scope="obj"
			                            class="dF aC"
			                        >
			                            <a-popover
			                                trigger="hover"
			                                placement="bottomRight"
			                                overlayClassName="popoverStyle"
			                            >
			                                <div slot="content">
			                                    <div
			                                        class="popoverContent dF aC"
			                                        @click="openInvoicePaymentModal(selectedInvoice, 'edit', obj)"
			                                    >
			                                        Edit
			                                    </div>
			                                    <div
			                                        class="popoverContent dF aC"
			                                        @click="deleteInvoicePayment(obj)"
			                                    >
			                                        Delete
			                                    </div>
			                                </div>
			                                <div class="more-option-icon mr-4">
			                                    <a-icon
			                                        style="line-height: 40px"
			                                        type="more"
			                                    />
			                                </div>
			                            </a-popover>
			                        </div>
			                    </a-table>
							</a-card>
						</div>
						<div class="profile mt-5" v-if="selectedInvoice.refunds && selectedInvoice.refunds.length">
							<a-card>
			                    <h4>Invoice Refunds</h4>
								<hr
			                        class="mt-3"
			                        v-if="$mq != 'sm'"
			                        style="margin-left: -24px; margin-right: -24px"
			                    />
								<a-table
			                        :rowKey="(e, i) => e.id"
			                        :columns="refundColumns"
			                        :dataSource="selectedInvoice.refunds"
			                        class="list-view"
									:loading="loading"
			                    >
			                        <div slot="amount" slot-scope="obj">
			                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
											useGrouping: false,
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										}) || 0 }} ({{ selectedInvoice.currency || "USD"}})
			                        </div>
									<div slot="reason" slot-scope="obj">
			                            {{ obj.reason  }}
			                        </div>
									<div slot="createdAt" slot-scope="obj">
			                            {{
			                                getDateTime(obj.createdAt)
			                            }}
			                        </div>
			                    </a-table>
							</a-card>
						</div>
						<div class="profile mt-5" v-if="selectedInvoice.instance_feature_log && selectedInvoice.instance_feature_log.description">
			                <a-card>
								<h4>Description</h4>
								<hr
			                        class="mt-3"
			                        v-if="$mq != 'sm'"
			                        style="margin-left: -24px; margin-right: -24px"
			                    />
								<h6>
									{{ selectedInvoice.instance_feature_log.description }}
								</h6>
							</a-card>
						</div>
						<div class="profile mt-5" v-if="timeline.length">
			                <a-card>
			                    <h4>Timeline</h4>
								<hr
			                        class="mt-3"
			                        v-if="$mq != 'sm'"
			                        style="margin-left: -24px; margin-right: -24px"
			                    />
								<a-row class="mt-3">
									<a-col :span="8" class="mt-4">
										<a-timeline>
											<div class="dF cursor-pointer" v-for="(activity, i) in timeline" :key="i" @click="onActivitySelect(activity)">
												<div class="mr-3" style="margin-top: -5px; color: #9EA0A5; font-size: medium; min-width: 135px;">
													{{ activity.date }}
												</div>
												<a-timeline-item>
													<div class="activity" :class="activity.id === (selectedActivity && selectedActivity.id) ? 'selected-activity' : ''">
														{{ activity.title }}
													</div>
												</a-timeline-item>
											</div>
										</a-timeline>
									</a-col>
									<a-col :span="16">
										<div v-if="selectedActivity">
											<a-card :title="`${selectedActivity.title} - ${selectedActivity.date}`">
												<a-row>
							                        <a-col :span="$mq == 'sm' ? 12 : 16">
							                            <b>Package options </b>
							                        </a-col>
							                        <a-col
							                            :span="$mq == 'sm' ? 4 : 6"
							                            style="text-align: right"
							                        >
							                            <b>Subtotal</b>
							                        </a-col>
							                    </a-row>
												<hr
							                        v-if="$mq != 'sm'"
							                        style="margin-left: -24px; margin-right: -24px"
							                    />
												<a-row v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.package">
							                        <a-col :span="$mq == 'sm' ? 12 : 16">
							                            <div class="text-primary">RECURRING FEES</div>
							                            {{ selectedActivity.invoice.details.package.name }}
							                        </a-col>
							                        <a-col
							                            :span="$mq == 'sm' ? 4 : 6"
							                            style="text-align: right"
							                            class="text-black"
							                        >
							                            <b
							                                >${{
							                                    selectedActivity.invoice.details.package.price
							                                }}</b
							                            >
							                        </a-col>
							                    </a-row>
												<hr
							                        v-if="$mq != 'sm' && selectedActivity.invoice.details && selectedActivity.invoice.details.package"
							                        style="margin-left: -24px; margin-right: -24px"
							                    />

												<div
							                        v-for="(
							                            additional, index
							                        ) in (selectedActivity.invoice.details && selectedActivity.invoice.details.selectedPackageExtras || [])"
							                        :key="index"
							                    >
							                        <a-row>
							                            <a-col :span="$mq == 'sm' ? 12 : 16">
							                                <span
							                                    v-if="additional.name === 'Application Programming Interface (API Calls)' || additional.name === 'API Calls' || additional.name === 'Online Video Chat Support'"
							                                >
							                                    <div class="text-primary">
							                                        ADD ON FEES
							                                    </div>
							                                    {{ additional.name }}
							                                </span>
							                                <span
							                                    v-else-if="additional.name === 'Onboarding'"
							                                >
							                                    <div class="text-primary">
							                                        ONE TIME FEES
							                                        <a-tooltip overlayClassName="change-tooltip-color">
							                                            <template slot="title">
							                                                One time fee (Applicable if you
							                                                are a first time user of
							                                                Bildhive)
							                                            </template>
							                                            <a-icon
							                                                type="question-circle"
							                                                style="
							                                                    font-size: 12px;
							                                                    color: black;
							                                                    margin-left: 5px;
							                                                "
							                                            />
							                                        </a-tooltip>
							                                    </div>
							                                    {{ additional.name }}
							                                </span>
							                                <span
							                                    v-else-if="
							                                        additional.name === 'Lot/Door Fee'
							                                    "
							                                >
							                                    <div class="text-primary">
							                                        <a-popover placement="right">
							                                            <template slot="title">
							                                                <h3
							                                                    class="
							                                                        text-primary text-center
							                                                    "
							                                                >
							                                                    {{
							                                                        instance.productType ==
							                                                        "highrise"
							                                                            ? "DOOR FEES"
							                                                            : "LOT FEES"
							                                                    }}
							                                                </h3>
							                                            </template>
							                                            <template slot="content">
							                                                <div
							                                                    style="
							                                                        text-align: justify;
							                                                        width: 400px;
							                                                    "
							                                                >
							                                                    Includes 50 lots/doors per
							                                                    development project.
							                                                    Additional lots/doors are
							                                                    charged on a per door basis
							                                                    starting at $30 per door.
							                                                </div>
							                                                <div
							                                                    class="
							                                                        modal-list
							                                                        d-flex
							                                                        my-2
							                                                    "
							                                                >
							                                                    <ul class="p-0">
							                                                        <li
							                                                            class="
							                                                                modal-list-title
							                                                            "
							                                                        >
							                                                            Door Fee
							                                                        </li>
							                                                        <li>First 50</li>
							                                                        <li>51 – 100</li>
							                                                        <li>101 – 150</li>
							                                                        <li>151 – 200</li>
							                                                        <li>201+</li>
							                                                    </ul>
							                                                    <ul>
							                                                        <li
							                                                            class="
							                                                                modal-list-title
							                                                            "
							                                                        >
							                                                            Billed at
							                                                        </li>
							                                                        <li>$0</li>
							                                                        <li>$30</li>
							                                                        <li>$25</li>
							                                                        <li>$20</li>
							                                                        <li>$15</li>
							                                                    </ul>
							                                                </div>
							                                                <div
							                                                    style="
							                                                        text-align: justify;
							                                                        width: 400px;
							                                                    "
							                                                >
							                                                    Each project subscription is
							                                                    inclusive of 50 lots/doors.
							                                                    Thereafter, a per door
							                                                    charge applies. The charge
							                                                    is based on the number of
							                                                    lots/doors to be processed
							                                                    and supported in your
							                                                    development project. The
							                                                    Door fee is a one-time fee
							                                                    that is over and above your
							                                                    monthly subscription fee and
							                                                    it is charged upon initial
							                                                    project set up.
																				Note: Currency rates apply.
							                                                </div>
							                                            </template>
							                                            {{
							                                                selectedInvoice.instance.productType ==
							                                                "highrise"
							                                                    ? "DOOR"
							                                                    : "LOT"
							                                            }}
							                                            FEES
							                                            <a-icon
							                                                type="question-circle"
							                                                style="
							                                                    font-size: 12px;
							                                                    color: black;
							                                                    margin-left: 5px;
							                                                "
							                                            />
							                                        </a-popover>
							                                    </div>
							                                    {{
							                                        selectedInvoice.instance.productType ==
							                                        "highrise"
							                                            ? `${additional.quantity} doors`
							                                            : `${additional.quantity} lots`
							                                    }}
							                                    total
							                                </span>
							                                <span
							                                    v-else-if="
							                                        additional.name === 'Training Hours'
							                                    "
							                                >
							                                    <div class="text-primary">
							                                        TRAINING HOUR BLOCKS
							                                        <a-tooltip overlayClassName="change-tooltip-color">
							                                            <template slot="title">
							                                                Additional training hours are
							                                                charged in 4 hour blocks @
							                                                $500/block.
																			<br/>
																			Note: Currency rates apply.
							                                            </template>
							                                            <a-icon
							                                                type="question-circle"
							                                                style="
							                                                    font-size: 12px;
							                                                    color: black;
							                                                    margin-left: 5px;
							                                                "
							                                            />
							                                        </a-tooltip>
							                                    </div>
							                                    {{ additional.quantity }}
							                                    Blocks ({{ additional.quantity * 4 }} Hours)
							                                </span>
															<span
							                                    v-else-if="
							                                        additional.name === 'Email sends'
							                                    "
							                                >
							                                    <div class="text-primary tT">
							                                        {{ additional.name }}
							                                    </div>
							                                    {{ additional.quantity }} Emails
							                                </span>
															<span
							                                    v-else-if="additional.name === 'SMS'"
							                                >
							                                    <div class="text-primary">
							                                        ADD ON FEES
							                                    </div>
							                                    SMS Profile Setup
							                                </span>
															<span
							                                    v-else
							                                >
							                                    <div class="text-primary tT">
							                                        {{ additional.name }}
							                                    </div>
							                                    {{ additional.quantity }}
							                                </span>
							                            </a-col>
							                            <a-col
							                                :span="$mq == 'sm' ? 4 : 6"
							                                style="text-align: right"
							                                class="text-black"
							                            >
							                                <b>${{ additional.price && additional.price.toLocaleString("en-US", {
																			useGrouping: true,
																			minimumFractionDigits: 2,
																			maximumFractionDigits: 2,
																		}) || (0).toFixed(2) }}</b>
							                            </a-col>
							                        </a-row>
							                        <hr
							                            v-if="$mq != 'sm'"
							                            style="margin-left: -24px; margin-right: -24px"
							                        />
							                    </div>

												<div
													v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.usageDetails"
							                    >
													<a-row>
							                            <a-col :span="$mq == 'sm' ? 12 : 16">
							                                <span>
							                                    <div class="text-primary">
							                                        USAGE FEES
							                                    </div>
							                                    {{ selectedActivity.invoice.details.usageDetails.name }}
							                                </span>
														</a-col>
														<a-col
							                                :span="$mq == 'sm' ? 4 : 6"
							                                style="text-align: right"
							                                class="text-black"
							                            >
							                                <b>${{ selectedActivity.invoice.details.usageDetails.price && selectedActivity.invoice.details.usageDetails.price.toLocaleString("en-US", {
																	useGrouping: true,
																	minimumFractionDigits: 2,
																	maximumFractionDigits: 2,
																}) || (0).toFixed(2) }}</b>
							                            </a-col>
													</a-row>
													<hr
							                            v-if="$mq != 'sm'"
							                            style="margin-left: -24px; margin-right: -24px"
							                        />
												</div>

												<a-row>
													<a-col :span="$mq == 'sm' ? 6 : 2" />
							                        <a-col
							                            :span="$mq == 'sm' ? 6 : 14"
							                            style="text-align: right"
							                        >
							                            <!-- <p>Subtotal before tax</p> -->
							                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.deductions && selectedActivity.invoice.details.deductions.length">Deductions <span>({{ selectedActivity.invoice.details.deductions.map(d => d.name).join(", ") }})</span></p>
							                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.appliedDiscounts && selectedActivity.invoice.details.appliedDiscounts.length">Discount <span>({{ selectedActivity.invoice.details.appliedDiscounts.map(d => d.name).join(", ") }})</span></p>
							                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.taxes && selectedActivity.invoice.details.taxes.length">Tax <span>({{ selectedActivity.invoice.details.taxes.map(t => t.name).join(", ") }})</span></p>
														<p v-if="selectedActivity.invoice.promoCode">Promo Code</p>
														<p><b>Total Amount</b></p>
							                            <p>Currency</p>
							                        </a-col>
							                        <a-col
							                            :span="$mq == 'sm' ? 4 : 6"
							                            style="text-align: right"
							                        >
							                            <!-- <p>${{ selectedActivity.invoice.subTotal }}</p> -->
														<p class="text-red" v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.deductions && selectedActivity.invoice.details.deductions.length">
							                                -${{
							                                    selectedActivity.invoice.details && selectedActivity.invoice.details.deductions && selectedActivity.invoice.details.deductions
							                                        .reduce((acc, discount) => {
							                                            return acc + discount.price;
							                                        }, 0)
							                                        .toFixed(2) || (0).toFixed(2)
							                                }}
							                            </p>
							                            <p class="text-red" v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.appliedDiscounts && selectedActivity.invoice.details.appliedDiscounts.length">
							                                -${{
							                                    selectedActivity.invoice.details && selectedActivity.invoice.details.appliedDiscounts && selectedActivity.invoice.details.appliedDiscounts
							                                        .reduce((acc, discount) => {
							                                            return acc + discount.price;
							                                        }, 0)
							                                        .toFixed(2) || (0).toFixed(2)
							                                }}
							                            </p>
							                            <p v-if="selectedActivity.invoice.details && selectedActivity.invoice.details.taxes && selectedActivity.invoice.details.taxes.length">
							                                ${{
							                                    selectedActivity.invoice.details && selectedActivity.invoice.details.taxes && selectedActivity.invoice.details.taxes
																.reduce(
							                                        (acc, tax) => {
							                                            return acc + tax.price;
							                                        },
							                                        0
							                                    ).toFixed(2) || (0).toFixed(2)
							                                }}
							                            </p>
														<p>
															<a-tag v-if="selectedActivity.invoice.promoCode" class="m-0">{{selectedActivity.invoice.promoCode}}</a-tag>
														</p>
							                            <p>
							                                <b>
							                                    ${{ selectedActivity.invoice.total }}
							                                </b>
							                            </p>
							                            <p>{{ selectedActivity.invoice.currency || 'USD' }}</p>
							                        </a-col>
							                    </a-row>
											</a-card>
											<div class="profile mt-5" v-if="selectedActivity.invoice.payments && selectedActivity.invoice.payments.length">
												<h5>Offline Payment {{ selectedActivity.title.split(' ')[1] }}</h5>
												<a-table
							                        :rowKey="(e, i) => e.id"
							                        :columns="paymentsColumns"
							                        :dataSource="selectedActivity.invoice.payments"
							                        class="list-view"
													:loading="loading"
							                    >
							                        <div slot="amount" slot-scope="obj">
							                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
															useGrouping: false,
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}) || 0 }} ({{ selectedActivity.invoice.currency || "USD"}})
							                        </div>
							                        <div slot="paymentDate" slot-scope="obj">
							                            {{
							                                getDateTime(obj.paymentDate)
							                            }}
							                        </div>
													<div slot="paymentMode" slot-scope="obj">
							                            {{ obj.paymentMode === 'check' ? 'Check' :  obj.paymentMode === 'bank_transfer' ? 'Bank Transfer' : 'Cash'  }}
							                        </div>
													<div slot="note" slot-scope="obj">
							                            {{ obj.note  }}
							                        </div>
							                    </a-table>
											</div>
											<div class="profile mt-5" v-if="selectedActivity.invoice.refunds && selectedActivity.invoice.refunds.length">
												<h5>Invoice Refunds</h5>
												<a-table
							                        :rowKey="(e, i) => e.id"
							                        :columns="refundColumns"
							                        :dataSource="selectedActivity.invoice.refunds"
							                        class="list-view"
													:loading="loading"
							                    >
							                        <div slot="amount" slot-scope="obj">
							                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
															useGrouping: false,
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														}) || 0 }} ({{ selectedInvoice.currency || "USD"}})
							                        </div>
													<div slot="reason" slot-scope="obj">
							                            {{ obj.reason  }}
							                        </div>
													<div slot="createdAt" slot-scope="obj">
							                            {{
							                                getDateTime(obj.createdAt)
							                            }}
							                        </div>
							                    </a-table>
											</div>
										</div>
									</a-col>
								</a-row>
							</a-card>
						</div>
                    </div>
                    <a-table
                        v-else
                        :rowKey="(e, i) => e.id"
                        :columns="invoiceColumns"
						:row-selection="{ selectedRowKeys: selectedInvoiceIds, onChange: onSelectChange }"
                        :dataSource="invoices"
                        :loading="loading"
                        class="list-view"
                    >
                        <div slot="invoiceNumber" slot-scope="invoice">
                            {{
                                !invoice.invoiceNumber
                                    ? "N/A"
                                    : invoice.invoiceNumber
                            }}
                        </div>
                        <div
                            slot="dueDate"
                            slot-scope="invoice"
                            style="color: #9ea0a5"
                        >
                            {{
                                !invoice.validUntil
                                    ? "N/A"
                                    : getDate(+invoice.validUntil)
                            }}
                        </div>
                        <div
                            slot="invoiceDate"
                            slot-scope="invoice"
                            style="color: #9ea0a5; min-width: 150px"
                        >
                            {{
                                !invoice.createdAt
                                    ? "N/A"
                                    : getDate(invoice.createdAt)
                            }}
                        </div>
						<div slot="price" slot-scope="invoice">
							{{ !invoice.subTotal ? "$0" : `$${invoice.subTotal.toLocaleString(
								"en-US",
								{
									useGrouping: true,
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}
							)}` }} ({{ invoice.currency || "USD" }})
						</div>
						<div slot="tax" slot-scope="invoice">
							{{ !invoice.tax ? "$0" : `$${invoice.tax.toLocaleString(
								"en-US",
								{
									useGrouping: true,
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}
							)}` }} ({{ invoice.currency || "USD" }})
						</div>
						<div slot="deductions" slot-scope="invoice">
							<div v-if="invoice && invoice.details && invoice.details.deductions && invoice.details.deductions.length">
								<div v-for="(deductions, index) in getDeductions(invoice)" :key="index">
									{{ deductions }}
								</div>
							</div>
						</div>
						<div slot="discounts" slot-scope="invoice">
							<div v-if="invoice && invoice.details && invoice.details.appliedDiscounts && invoice.details.appliedDiscounts.length">
								<div v-for="(discount, index) in getDiscounts(invoice)" :key="index">
									{{ discount }}
								</div>
							</div>
						</div>
                        <div
                            slot="total"
                            slot-scope="invoice"
                            class="text-dark"
                            style="font-size: 15px"
                        >
                            {{
                                !invoice.total ? "$0" : `$${invoice.total.toLocaleString(
								"en-US",
								{
									useGrouping: true,
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								}
							)}`}} ({{ invoice.currency || "USD" }})
                        </div>
                        <div slot="status" slot-scope="invoice">
                            <span
                                class="project-button py-1 px-3"
                                :style="
                                    invoice.paid
                                        ? 'background-color:#28C791'
                                        : 'background-color:#db5358'
                                "
                            >
                                {{ invoice.paid ? "PAID" : "UNPAID" }}</span
                            >
                        </div>
						<div slot="paymentMode" slot-scope="invoice">
                            {{ invoice.paymentMode === 'offline' ? 'Offline' : 'Online'  }}
                        </div>
                        <div slot="refundTotal" slot-scope="invoice">
                            {{ calculateTotalRefund(invoice.refunds) }} ({{
                                invoice.currency || "USD"
                            }})
                        </div>
                        <div slot="refundStatus" slot-scope="invoice">
                            <span
                                class="project-button py-1 px-3"
                                :style="
                                    invoice.refundStatus
                                        ? invoice.refundStatus === 'partial'
                                            ? 'background-color:#ffc107'
                                            : 'background-color: var(--primary)'
                                        : ''
                                "
                            >
								<a-tooltip overlayClassName="change-tooltip-color">
			                        <template slot="title" v-if="invoice.refunds && invoice.refunds.length">
			                            <div v-for="r in invoice.refunds" :key="r.id">
											<p> {{r.reason || 'N/A'}}: ${{r.amount && r.amount.toLocaleString("en-US", {
													useGrouping: false,
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
												}) || 0}} ({{invoice.currency || "USD"}})
											</p>
										</div>
			                        </template>
			                        {{
	                                    invoice.refundStatus
	                                        ? invoice.refundStatus === "partial"
	                                            ? "PARTIAL"
	                                            : "FULL"
	                                        : ""
	                                }}
			                    </a-tooltip>
							</span>
                        </div>
                        <div
                            slot="actions"
                            slot-scope="invoice"
                            class="dF"
                            style="justify-content: flex-end"
                        >
                            <a-popover
                                trigger="hover"
                                placement="bottomRight"
                                overlayClassName="popoverStyle"
                            >
                                <div slot="content">
									<div
                                        class="popoverContent dF aC"
                                        @click="editInvoice(invoice)"
                                    >
                                        View Invoice
                                    </div>
									<div
                                        class="popoverContent dF aC"
                                        @click="printInvoice(invoice)"
                                    >
                                        Print Invoice
                                    </div>
                                    <div
                                        v-if="!invoice.paid"
                                        class="popoverContent dF aC"
                                        @click="makePayment(invoice)"
                                    >
                                        Make A Payment
                                    </div>
                                    <div
                                        v-else-if="!invoice.refundStatus"
                                        class="popoverContent dF aC"
                                        @click="
                                            selectedInvoice = invoice;
                                            refundModal = true;
                                            refundAmount = 0;
                                            refundReason = '';
                                        "
                                    >
                                        Refund Payment
                                    </div>
									<div
										v-if="!invoice.paid"
                                        class="popoverContent dF aC"
                                        @click="voidInvoice(invoice)"
                                    >
                                        Void Invoice
                                    </div>
                                </div>
                                <div class="more-option-icon mr-4">
                                    <a-icon
                                        style="line-height: 40px"
                                        type="more"
                                    />
                                </div>
                            </a-popover>
                        </div>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="3" tab="Training">
                    <a-table
                        :rowKey="(e, i) => e.id"
                        :columns="trainingColumns"
                        :dataSource="trainings"
                        class="list-view"
                    >
                        <div slot="description" slot-scope="obj">
                            {{ obj.description }}
                        </div>
                        <div slot="dateTime" slot-scope="obj">
                            {{
                                !obj.dateTime
                                    ? "N/A"
                                    : getDateTime(obj.dateTime)
                            }}
                        </div>
                        <div slot="duration" slot-scope="obj">
                            {{ obj.rawValue.replaceAll("h", "") + "h" }}
                        </div>
                        <div slot="remainingHours" slot-scope="obj">
                            {{ getHours(obj.value) + "h" }}
                        </div>
                        <div slot="trainer" slot-scope="obj">
                            <div
                                v-for="trainer in obj.trainers"
                                :key="trainer.id"
                                >{{
                                    trainer.firstName + " " + trainer.lastName
                                }} ({{ trainer.email }})
							</div>
                        </div>
                        <div slot="trainee" slot-scope="obj">
                            <div
                                v-for="trainee in obj.trainees"
                                :key="trainee.id"
                                >{{
                                    trainee.firstName + " " + trainee.lastName
                                }} ({{ trainee.email }})
                            </div>
                        </div>
                        <div
                            slot="actions"
                            slot-scope="obj"
                            class="dF"
                            style="justify-content: flex-end"
                        >
                            <a-popover
                                trigger="hover"
                                placement="bottomRight"
                                overlayClassName="popoverStyle"
                            >
                                <div slot="content">
                                    <div
                                        class="popoverContent dF aC"
                                        @click="openFeatureLogEdit(obj, 'training')"
                                    >
                                        Edit
                                    </div>
                                    <div
                                        class="popoverContent dF aC"
                                        @click="deleteFeatureLog(obj, 'training')"
                                    >
                                        Delete
                                    </div>
                                </div>
                                <div class="more-option-icon mr-4">
                                    <a-icon
                                        style="line-height: 40px"
                                        type="more"
                                    />
                                </div>
                            </a-popover>
                        </div>
                    </a-table>
                </a-tab-pane>
				<a-tab-pane key="4" tab="Setup Fee">
                    <a-table
                        :rowKey="(e, i) => e.id"
                        :columns="setUpFeeColumns"
                        :dataSource="setupFees"
                        class="list-view"
                    >
                        <div slot="description" slot-scope="obj">
                            {{ obj.description }}
                        </div>
                        <div slot="dateTime" slot-scope="obj">
                            {{
                                !obj.dateTime
                                    ? "N/A"
                                    : getDateTime(obj.dateTime)
                            }}
                        </div>
                        <div slot="duration" slot-scope="obj">
                            {{ obj.rawValue.replaceAll("h", "") + "h" }}
                        </div>
						<div slot="rate" slot-scope="obj">
                            ${{ obj.rate }}
                        </div>
						<div slot="amount" slot-scope="obj">
                            ${{ obj.amount && obj.amount.toLocaleString("en-US", {
								useGrouping: false,
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) || 0 }} ({{instance.currency || "USD"}})
                        </div>
                        <div slot="remainingHours" slot-scope="obj">
                            {{ getHours(obj.value) + "h" }}
                        </div>
                        <div slot="trainer" slot-scope="obj">
                            <div
                                v-for="trainer in obj.trainers"
                                :key="trainer.id"
                                >{{
                                    trainer.firstName + " " + trainer.lastName
                                }} ({{ trainer.email }})
							</div>
                        </div>
                        <div slot="trainee" slot-scope="obj">
                            <div
                                v-for="trainee in obj.trainees"
                                :key="trainee.id"
                                >{{
                                    trainee.firstName + " " + trainee.lastName
                                }} ({{ trainee.email }})
							</div>
                        </div>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
        </div>
        <a-modal
            v-model="changeBillingOwnerModal"
            title="Change Billing Owner"
            ok-text="UPDATE"
            @ok="updateBillingOwner"
            :confirmLoading="confirmLoading"
            :ok-button-props="{
                props: {
                    disabled:
                        (instance.paymentMode != 'offline' && (noPaymentMethodSetup || !updatedBillingOwner.paymentMethod)),
                },
            }"
        >
            <BhLoading :show="loading" />
            <a-form-model ref="ruleForm" :model="updatedBillingOwner">
                <a-form-model-item
                    label="New Billing Owner"
                    prop="owner"
                    required
                    :rules="req('Please choose a New Billing Owner')"
                >
                    <a-select
                        show-search
                        placeholder="Choose a New Billing Owner"
                        option-filter-prop="email"
                        :filter-option="filterOption"
                        v-model="updatedBillingOwner.owner"
                        @change="fetchPaymentDetails"
                    >
                        <a-select-option
                            v-for="user in filterUsers"
                            :key="user.id"
                            :value="user.id"
                        >
                            {{ user.email }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <div v-if="instance.paymentMode != 'offline' && noPaymentMethodSetup && !updatedBillingOwner.paymentMethod">
                    <a-alert
                        message="No payment methods is setup for the selected user. Please first setup the payment method or change the user."
                        type="info"
						show-icon
                    />
                </div>
                <a-form-model-item
                    v-if="instance.paymentMode != 'offline' && paymentMethods.length"
                    label="Payment Method"
                    prop="paymentMethod"
                    required
                    :rules="req('Please choose a New Payment Method')"
                >
                    <a-select
                        show-search
                        placeholder="Choose a New Payment Method"
                        option-filter-prop="cardNo"
                        :filter-option="filterOption"
                        v-model="updatedBillingOwner.paymentMethod"
                    >
                        <a-select-option
                            v-for="payment in paymentMethods"
                            :key="payment.id"
                            :value="payment.id"
                        >
							XXXX-XXXX-XXXX-{{ payment.details.cardNo }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
		<a-modal
            v-model="changeProjectOwnerModal"
            title="Change Project Owner"
            ok-text="UPDATE"
            @ok="updateProjectOwner"
            :confirmLoading="confirmLoading"
            :ok-button-props="{
                props: {
                    disabled: !updatedProjectOwner.owner,
                },
            }"
        >
            <BhLoading :show="loading" />
            <a-form-model ref="ruleForm" :model="updatedProjectOwner">
                <a-form-model-item
                    label="New Project Owner"
                    prop="owner"
                    required
                    :rules="req('Please choose a New Project Owner')"
                >
                    <a-select
                        show-search
                        placeholder="Choose a New Project Owner"
                        option-filter-prop="email"
                        :filter-option="filterOption"
                        v-model="updatedProjectOwner.owner"
                    >
                        <a-select-option
                            v-for="user in filterUsers"
                            :key="user.id"
                            :value="user.id"
                        >
                            {{ user.email }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
        <a-modal
            v-model="unlockModal"
            title="Unlock Project"
            ok-text="UNLOCK PROJECT"
            @ok="unlockProject"
            :confirmLoading="confirmLoading"
        >
            <p>
                Unlock this project <strong>{{ project.name }}</strong
                >.
            </p>
        </a-modal>
        <a-modal
            v-model="lockModal"
            title="Lock Project"
            ok-text="LOCK PROJECT"
            @ok="lockProject"
            :confirmLoading="confirmLoading"
        >
            <p>
                All users will be blocked from accessing this project
                <strong>{{ project.name }}</strong
                >.
            </p>
			<a-checkbox v-model="chargeForSuspension">
                <span>
                    Charge for suspension
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox, one invoice will be generated with $249(USD) amount.
                        </template>
                        <a-icon
                            type="question-circle"
                            style="font-size: 12px"
                        />
                    </a-tooltip>
                </span>
            </a-checkbox>
        </a-modal>
        <a-modal
            v-model="deleteModal"
            title="Delete Your Project"
            ok-text="DELETE"
            @ok="onDelete"
            :confirmLoading="confirmLoading"
            :ok-button-props="{ props: { disabled: !deleteAccepted } }"
        >
            <p>
                You are about to delete your project
                <strong> {{ project.name }} </strong>. All your data will be
                deleted.
            </p>
            <p>
                <b>Consider downloading your data before deleting a project.</b>
            </p>
            <a-checkbox v-model="downloadData">
                <span>
                    DOWNLOAD DATA (Your data will consist of all your leads and
                    all your completed transactions.)
                    <a-tooltip overlayClassName="change-tooltip-color">
                        <template slot="title">
                            Upon selecting the checkbox an email with a link
                            will be send to you that will allow you to download
                            your data.
                        </template>
                        <a-icon
                            type="question-circle"
                            style="font-size: 12px"
                        />
                    </a-tooltip>
                </span>
            </a-checkbox>
            <br />
            <br />
            <a-checkbox v-model="deleteAccepted">
                I understand that deleting my project can’t be undone and all my
                data will be deleted.
            </a-checkbox>
        </a-modal>
        <a-modal
            v-model="refundModal"
            :title="`Refund for Payment ID: ${selectedInvoice.invoiceNumber}`"
            ok-text="INITIATE REFUND"
            @ok="refundPayment"
            :confirmLoading="confirmLoading"
            :centered="true"
        >
            <a-row
                style="
                    border: 1px solid #a3a3a3;
                    padding: 10px;
                    border-radius: 5px;
                "
            >
                <a-col :span="12" style="text-align: left">
                    <p>ID</p>
                    <p>Transaction ID</p>
                    <p>Payment Method</p>
                    <p>Paid Date</p>
                    <p>Amount</p>
                </a-col>
                <a-col :span="12" style="text-align: right">
                    <p>
                        <strong>{{ selectedInvoice.invoiceNumber }}</strong>
                    </p>
                    <p>
                        <strong>{{ selectedInvoice.invoiceNumber }}</strong>
                    </p>
                    <p>
                        <strong>
                            {{
                                currentPaymentMethod &&
                                currentPaymentMethod.details &&
                                currentPaymentMethod.details.ccBrand
                            }}
                        </strong>
                    </p>
                    <p>
                        <strong>{{
                            getDate(selectedInvoice.createdAt)
                        }}</strong>
                    </p>
                    <p>
                        <strong
                            >${{ selectedInvoice.total }} ({{
                                selectedInvoice.currency || "USD"
                            }})</strong
                        >
                    </p>
                </a-col>
            </a-row>

            <div class="mx-1 mt-3">
                <a-row :gutter="20" class="dF aC">
                    <a-col :span="8"> Reason for refund: </a-col>
                    <a-col :span="16">
                        <a-textarea
                            v-model="refundReason"
                            placeholder="Refund Reason"
                            :rows="4"
                        >
                        </a-textarea>
                    </a-col>
                </a-row>
                <a-row v-if="partialRefund" :gutter="20" class="mt-3 dF aC">
                    <a-col :span="8"> Partial refund amount: </a-col>
                    <a-col :span="16">
                        <a-input-number
                            v-model="refundAmount"
                            placeholder="Refund Amount"
                            style="width: 50%"
                        >
                        </a-input-number>
                    </a-col>
                </a-row>
            </div>
        </a-modal>
        <a-modal
            v-model="addFeatureLog"
            :title="featureLogModalTitle"
            :ok-text="featureLogModalOkText"
            @ok="addNewFeatureLog"
            @cancel="resetFeatureLogData"
            :confirmLoading="confirmLoading"
            :width="800"
        >
            <a-form-model ref="featureLog" :model="featureLog" class="profile">
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Description"
                            required
                            prop="description"
                            :rules="
                                req('Please enter the Description')
                            "
                        >
                            <a-textarea
                                v-model="featureLog.description"
                                placeholder="Description"
                                :rows="3"
                                required
								size="large"
                            >
                            </a-textarea>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="DateTime"
                            required
                            prop="dateTime"
                            :rules="req('Please enter the DateTime')"
                        >
                            <a-date-picker
                                v-model="featureLog.dateTime"
                                placeholder="DateTime"
                                :showTime="true"
                                required
                                style="width: 100%"
								size="large"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Duration Hours"
                            required
                            prop="rawValue"
                            :rules="
                                req('Please enter the Duration Hours')
                            "
                        >
                            <a-input-number
                                v-model="featureLog.rawValue"
                                placeholder="Duration Hours"
                                style="width: 100%"
                                required
								size="large"
                            >
                            </a-input-number>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12" v-if="featureLog.featureName === 'setup_fee'">
                        <a-form-model-item
                            label="Rate per Hour"
                            required
                            prop="rate"
                            :rules="
                                req('Please enter the Rate per Hour')
                            "
                        >
                            <a-input
                                v-model="featureLog.rate"
                                placeholder="Rate per Hour"
                                style="width: 100%"
                                required
								size="large"
								type="number"
								prefix="$"
								:addon-after="instance.currency || 'USD'"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
				</a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            :label="`${featureLog.featureName === 'setup_fee' ? 'Performed By' : 'Trainer'}`"
                            prop="trainers"
                            required
                            :rules="req(`Please choose a ${featureLog.featureName === 'setup_fee' ? 'Performer' : 'Trainer'}`)"
                        >
                            <a-select
                                mode="multiple"
                                show-search
                                :placeholder="`Choose a ${featureLog.featureName === 'setup_fee' ? 'Performer' : 'Trainer'}`"
                                option-filter-prop="children"
                                :filter-option="filterOption"
                                v-model="featureLog.trainers"
								size="large"
                            >
                                <a-select-option
                                    v-for="user in trainersPerformers"
                                    :key="user.id"
                                    :value="user.id"
                                >
                                    {{ user.firstName + " " + user.lastName }} ({{ user.email }})
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            :label="`${featureLog.featureName === 'setup_fee' ? 'Requested By' : 'Trainee'}`"
                            prop="trainees"
                            required
                            :rules="req(`Please choose a ${featureLog.featureName === 'setup_fee' ? 'Requester' : 'Trainee'}`)"
                        >
							<a-select
								mode="multiple"
								option-label-prop="label"
								:placeholder="`Choose a ${featureLog.featureName === 'setup_fee' ? 'Requester' : 'Trainee'}`"
								v-model="featureLog.trainees"
								style="width: 100%"
								:filterOption="false"
								@search="handleRequesterSearch"
								size="large"
							>
								<a-select-option
									v-for="user in requesterUserSearchResult"
									:key="user.value"
									:label="user.label"
									:value="user.value"
								>
									<div class="dF aC">
										<div class="f1">
											<div
												style="
													color: black;
													font-size: 15px;
												"
											>
												{{ user.label }} ({{ user.email }})
											</div>
										</div>
									</div>
								</a-select-option>
							</a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<div v-if="featureLog.featureName === 'setup_fee'">
					<a-alert
                        message="This will generate invoice for the instance and cannot be changed or deleted after. Make sure the details are correct."
                        type="warning"
						show-icon
                    />
				</div>
            </a-form-model>
        </a-modal>
        <a-modal
            v-model="resetPasswordModal"
            title="Reset Password"
            ok-text="SEND"
            @ok="resetPassword"
            :centered="true"
        >
            <p>
                Are you sure you want to send reset password email to
                <strong>
                    {{ selectedUser.email }}
                </strong>
                ?
            </p>
        </a-modal>
        <BillingModal
			v-if="paymentMethodModal.show"
            :show="paymentMethodModal.show"
            :load="paymentMethodModal.load"
            :action="paymentMethodModal.action"
            :modalTitle="paymentMethodModal.title"
            :modalOkText="paymentMethodModal.okText"
            :paymentMethodData="paymentMethodToUpdate"
            @close="resetPaymentMethodModalData"
            @cardPayment="updatePaymentMethod"
        ></BillingModal>

		<a-modal
            :footer="null"
            :centered="true"
            :visible="switchCardModal.visible"
            :width="720"
            @cancel="closeSwitchCardModal"
        >
            <h5 class="mb-3">Update Payment Method</h5>
            <div
                class="text-dark mb-5"
                v-if="Object.keys(switchCardModal.card).length != 0"
            >
                {{
                    `Choose a new payment method in replace of ${
                        switchCardModal.card.details.ccBrand == "visa"
                            ? "Visa"
                            : switchCardModal.card.details.ccBrand ==
                              "mc"
                            ? "Mastercard"
                            : "American Express"
                    } ${getCardNumber(
                        switchCardModal.card.details.cardNo
                    )}`
                }}
            </div>
            <div
                :style="paymentI == 0 ? 'margin-top:1rem' : ''"
                style="display: block"
                v-for="(payment, paymentI) in paymentMethodList"
                :key="payment.value + paymentI"
                :value="payment.value"
            >
                <div
                    class="dF aC"
                    style="justify-content: space-between"
                >
                    <div class="dF aC">
                        <a-radio
                            :checked="
                                switchCardModal.option == payment.value
                            "
                            @change="changeRadio"
                            :value="payment.value"
                        />
                        <div>
                            <div>{{ payment.label }}</div>
                            <div
                                @click="updateCard(payment.value)"
                                v-if="
                                    paymentI !=
                                    paymentMethods.length - 1
                                "
                                style="
                                    color: var(--orange);
                                    cursor: pointer;
                                "
                            >
                                Update card info
                            </div>
                        </div>
                    </div>
                    <i
                        @click="updateCard(payment.value)"
                        v-if="paymentI != paymentMethods.length - 1"
                        style="color: var(--orange); cursor: pointer"
                        class="fe fe-edit-2"
                    />
                </div>
                <hr v-if="paymentI != paymentMethods.length - 1" />
            </div>
            <div class="mt-5 dF aC legals">
                By continuing, you agree to the Bildhive&nbsp;
                <a
                    href="https://admin.bildhive.dev/system/terms"
                    target="_"
                    >Terms of Service</a
                >.
            </div>
            <div class="dF aC mt-5 dF jE">
                <a-button class="mr-3" @click="closeSwitchCardModal"
                    >CANCEL</a-button
                >
                <a-button
                    :loading="switchCardModal.loading"
                    @click="updatePaymentMethodCard"
                    type="primary"
                    >UPDATE</a-button
                >
            </div>
        </a-modal>

		<a-modal
            v-model="invoicePaymentModal.show"
            :title="invoicePaymentModal.title"
            :ok-text="invoicePaymentModal.okText"
            @ok="offlineInvoicePayment"
            @cancel="resetInvoicePaymentData"
            :confirmLoading="confirmLoading"
            :width="800"
        >
            <a-form-model ref="invoicePayment" :model="invoicePayment" class="profile">
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Amount"
                            required
                            prop="amount"
                            :rules="
                                req('Please enter the amount')
                            "
                        >
							<a-input
                                v-model="invoicePayment.amount"
                                placeholder="Invoice amount"
                                style="width: 100%"
                                size="large"
								type="number"
								prefix="$"
								:addon-after="instance.currency || 'USD'"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Payment Date"
                            required
                            prop="paymentDate"
                            :rules="req('Please enter the Payment Date')"
                        >
                            <a-date-picker
                                v-model="invoicePayment.paymentDate"
                                placeholder="Payment Date"
                                size="large"
                                style="width: 100%"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item
							label="Payment Mode"
                            prop="paymentMode"
							required
							:rules="req('Please select the Payment mode')"
                        >
                            <a-select
                                v-model="invoicePayment.paymentMode"
                                placeholder="Payment Mode"
                                size="large"
                            >
							<a-select-option value="cash">Cash</a-select-option>
								<a-select-option value="check">Check</a-select-option>
								<a-select-option value="bank_transfer">Bank Transfer</a-select-option>
                            </a-select>
                        </a-form-model-item>
					</a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Note"
                            prop="note"
                        >
                            <a-textarea
                                v-model="invoicePayment.note"
                                placeholder="Note"
                                :rows="3"
                            >
                            </a-textarea>
                        </a-form-model-item>
                    </a-col>
				</a-row>
                <a-row :gutter="16" v-if="invoicePayment.paymentMode === 'check'">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Check Number"
                            prop="metadata.checkNumber"
                        >
							<a-input
                                v-model="invoicePayment.metadata.checkNumber"
                                placeholder="Check Number"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Bank Name"
                            prop="metadata.bankName"
                        >
							<a-input
                                v-model="invoicePayment.metadata.bankName"
                                placeholder="Bank Name"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Check Date"
                            prop="metadata.checkDate"
                        >
                            <a-date-picker
                                v-model="invoicePayment.metadata.checkDate"
                                placeholder="Check Date"
                                size="large"
                                style="width: 100%"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16" v-if="invoicePayment.paymentMode === 'bank_transfer'">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Debit Bank Account Number"
                            prop="metadata.fromAccountNumber"
                        >
							<a-input-number
                                v-model="invoicePayment.metadata.fromAccountNumber"
                                placeholder="Debit Bank Account Number"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input-number>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Deposit Bank Account Number"
                            prop="metadata.toAccountNumber"
                        >
							<a-input-number
                                v-model="invoicePayment.metadata.toAccountNumber"
                                placeholder="Deposit Bank Account Number"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input-number>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Transfer Date"
                            prop="metadata.transferDate"
                        >
                            <a-date-picker
                                v-model="invoicePayment.metadata.transferDate"
                                placeholder="Transfer Date"
                                size="large"
                                style="width: 100%"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16" v-if="invoicePayment.paymentMode === 'cash'">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Received From"
                            prop="metadata.receivedFrom"
                        >
							<a-input
                                v-model="invoicePayment.metadata.receivedFrom"
                                placeholder="Received From"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Received By"
                            prop="metadata.receivedBy"
                        >
							<a-input
                                v-model="invoicePayment.metadata.receivedBy"
                                placeholder="Received By"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>
		<a-modal
            v-model="bulkPaymentModal.show"
            title="Offline Invoice Payment"
            ok-text="SUBMIT"
            @ok="createBulkPayment"
            @cancel="resetInvoicePaymentData"
            :confirmLoading="confirmLoading"
            :width="800"
        >
            <a-form-model ref="invoicePayment" :model="bulkPaymentModal.invoicePayment" class="profile">
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Payment Date"
                            required
                            prop="paymentDate"
                            :rules="req('Please enter the Payment Date')"
                        >
                            <a-date-picker
                                v-model="bulkPaymentModal.invoicePayment.paymentDate"
                                placeholder="Payment Date"
                                size="large"
                                style="width: 100%"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
						<a-form-model-item
							label="Payment Mode"
                            prop="paymentMode"
							required
							:rules="req('Please select the Payment mode')"
                        >
                            <a-select
                                v-model="bulkPaymentModal.invoicePayment.paymentMode"
                                placeholder="Payment Mode"
                                size="large"
                            >
							<a-select-option value="cash">Cash</a-select-option>
								<a-select-option value="check">Check</a-select-option>
								<a-select-option value="bank_transfer">Bank Transfer</a-select-option>
                            </a-select>
                        </a-form-model-item>
					</a-col>
                </a-row>
				<a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Note"
                            prop="note"
                        >
                            <a-textarea
                                v-model="bulkPaymentModal.invoicePayment.note"
                                placeholder="Note"
                                :rows="3"
                            >
                            </a-textarea>
                        </a-form-model-item>
                    </a-col>
				</a-row>
                <a-row :gutter="16" v-if="bulkPaymentModal.invoicePayment.paymentMode === 'check'">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Check Number"
                            prop="metadata.checkNumber"
                        >
							<a-input
                                v-model="bulkPaymentModal.invoicePayment.metadata.checkNumber"
                                placeholder="Check Number"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Bank Name"
                            prop="metadata.bankName"
                        >
							<a-input
                                v-model="bulkPaymentModal.invoicePayment.metadata.bankName"
                                placeholder="Bank Name"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Check Date"
                            prop="metadata.checkDate"
                        >
                            <a-date-picker
                                v-model="bulkPaymentModal.invoicePayment.metadata.checkDate"
                                placeholder="Check Date"
                                size="large"
                                style="width: 100%"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16" v-if="bulkPaymentModal.invoicePayment.paymentMode === 'bank_transfer'">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Debit Bank Account Number"
                            prop="metadata.fromAccountNumber"
                        >
							<a-input-number
                                v-model="bulkPaymentModal.invoicePayment.metadata.fromAccountNumber"
                                placeholder="Debit Bank Account Number"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input-number>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Deposit Bank Account Number"
                            prop="metadata.toAccountNumber"
                        >
							<a-input-number
                                v-model="bulkPaymentModal.invoicePayment.metadata.toAccountNumber"
                                placeholder="Deposit Bank Account Number"
                                style="width: 100%"
                                size="large"
                            >
                            </a-input-number>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Transfer Date"
                            prop="metadata.transferDate"
                        >
                            <a-date-picker
                                v-model="bulkPaymentModal.invoicePayment.metadata.transferDate"
                                placeholder="Transfer Date"
                                size="large"
                                style="width: 100%"
                            >
                            </a-date-picker>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16" v-if="bulkPaymentModal.invoicePayment.paymentMode === 'cash'">
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Received From"
                            prop="metadata.receivedFrom"
                        >
							<a-input
                                v-model="bulkPaymentModal.invoicePayment.metadata.receivedFrom"
                                placeholder="Received From"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Received By"
                            prop="metadata.receivedBy"
                        >
							<a-input
                                v-model="bulkPaymentModal.invoicePayment.metadata.receivedBy"
                                placeholder="Received By"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
				<a-row :gutter="16" v-for="(invoice, index) in bulkPaymentModal.invoicePayment.invoices" :key="invoice.id">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Invoice Number"
                            required
                        >
							<a-input
                                v-model="bulkPaymentModal.invoicePayment.invoices[index].invoiceNumber"
                                placeholder="Invoice Number"
                                size="large"
								type="number"
								disabled
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
					<a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            label="Amount"
                            required
                            :prop="`invoices[${index}].amount`"
                            :rules="
                                req('Please enter the amount')
                            "
                        >
							<a-input
                                v-model="bulkPaymentModal.invoicePayment.invoices[index].amount"
                                placeholder="Invoice amount"
                                style="width: 100%"
                                size="large"
								type="number"
								prefix="$"
								:addon-after="bulkPaymentModal.invoicePayment.invoices[index].currency || 'USD'"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>
		<div>
			<InvoicePDF :invoice="invoiceToDownload" :userPaymentMethods ="paymentMethods" @close="invoiceToDownload = null" />
		</div>
		<MultipleInvoicePDF @completed="selectedInvoiceIds = []"/>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import BillingModal from "@/views/projects/billingmodal";
import valid from "card-validator";
import AllFeaturesList from "@/components/common/allFeaturesList";
import moment from "moment";
import BhLoading from "bh-mod/components/common/Loading";
import InvoicePDF from "@/components/common/InvoicePDF";
import MultipleInvoicePDF from "@/components/common/MultipleInvoicePDF";

export default {
    components: { BillingModal, AllFeaturesList, BhLoading, InvoicePDF, MultipleInvoicePDF },

    data() {
        return {
            loading: false,
            lockModal: false,
            unlockModal: false,
            deleteModal: false,
            deleteAccepted: false,
            downloadData: false,
            changeInPlan: false,
			updateAddons: false,
            loadModal: false,
            showAllFeatures: false,
            editingInvoice: false,
            refundModal: false,
            partialRefund: false,
            addFeatureLog: false,
            confirmLoading: false,
            resetPasswordModal: false,
			changeBillingOwnerModal: false,
            changeProjectOwnerModal: false,
            noPaymentMethodSetup: false,
			chargeForSuspension: false,
			bulkPaymentModal: {
				show: false,
				invoicePayment: {
					invoices: [],
					paymentDate: null,
					note: "",
					paymentMode: "check",
					metadata: {
						checkNumber: "",
						bankName: "",
						fromAccountNumber: "",
						toAccountNumber: "",
						checkDate: null,
						transferDate: null,
						receivedFrom: "",
						receivedBy: ""
					},
					instance: ""
				}
			},
			invoiceToDownload: null,
            selectedUser: {},
            selectedInvoice: {},
			currentPaymentMethod: {},
            featureLogModalTitle: "Add New Training",
            featureLogModalOkText: "SUBMIT",
            activeTab: "1",
            couponCode: "",
            validCouponCode: "",
            current: 1,
            lotsOrDoors: 0,
            trainingHourBlocks: 0,
            refundReason: "",
            refundAmount: 0,
            packages: [],
            packageExtras: [],
            users: [],
            children: [],
            instances: [],
            invoices: [],
            paymentMethods: [],
			paymentMethodToUpdate: null,
			switchCardModal: {
                card: {},
                visible: false,
                option: "",
                loading: false,
            },
			paymentMethodModal: {
                show: false,
                load: false,
                title: "Add New Card",
                okText: "Add Card",
                action: "add",
                paymentMethodId: "",
            },
			updatedBillingOwner: {
				owner: "",
                paymentMethod: "",
			},
            updatedProjectOwner: {
                owner: "",
            },
			paymentMode: 'online',
			invoicePaymentModal: {
				show: false,
				title: 'Add Offline Invoice Payment',
				okText: "SUBMIT",
				selectedInvoice: null,
				selectedInvoicePayment: null,
			},
			invoicePayment: {
				invoice: "",
				paymentDate: null,
				amount: 0,
				note: "",
				paymentMode: "check",
				metadata: {
					checkNumber: "",
					bankName: "",
					fromAccountNumber: "",
					toAccountNumber: "",
					checkDate: null,
					transferDate: null,
					receivedFrom: "",
					receivedBy: ""
				},
				instance: ""
			},
            instance: {},
            selectedExtraPackages: {},
            instancePriceDetails: null,
            selectedPackageId: null,
			timeline:[],
			selectedActivity: null,
            steps: [
                {
                    title: "Step 1",
                    description: "Select your plan",
                },
                {
                    title: "Finish",
                    description: "How would you like to pay?",
                },
            ],
			addOnSteps: [
                {
                    title: "Step 1",
                    description: "Select your Addons",
                },
                {
                    title: "Finish",
                    description: "How would you like to pay?",
                },
            ],
            userColumns: [
                {
                    title: "Full Name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                    sorter: (a, b) =>
                        a.firstName + a.lastName < b.firstName + b.lastName
                            ? -1
                            : a.firstName + a.lastName >
                              b.firstName + b.lastName
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Role",
                    key: "role",
                    scopedSlots: { customRender: "role" },
                    sorter: (a, b) =>
                        a.role < b.role ? -1 : a.role > b.role ? 1 : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                    width: "10%",
                },
            ],
            invoiceColumns: [
                {
                    title: "Invoice #",
                    key: "invoiceNumber",
                    scopedSlots: { customRender: "invoiceNumber" },
                    sorter: (a, b) =>
                        (a.invoiceNumber || 0) - (b.invoiceNumber || 0),
                    sortDirections: ["descend", "ascend"],
					defaultSortOrder: 'descend',
                },
				{
                    title: "Invoice Date",
                    key: "invoiceDate",
                    scopedSlots: { customRender: "invoiceDate" },
                    sorter: (a, b) =>
                        (moment(a.createdAt).format("x") || 0) -
                        (moment(b.createdAt).format("x") || 0),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Next Invoice Date",
                    key: "dueDate",
                    scopedSlots: { customRender: "dueDate" },
                    sorter: (a, b) => (a.validUntil || 0) - (b.validUntil || 0),
                    sortDirections: ["descend", "ascend"],
                },
				{
                    title: "SubTotal",
                    key: "price",
                    scopedSlots: { customRender: "price" },
                    sorter: (a, b) => (a.subTotal || 0) - (b.subTotal || 0),
                    sortDirections: ["descend", "ascend"],
                },
				{
                    title: "Deductions",
                    key: "deductions",
                    scopedSlots: { customRender: "deductions" },
                    sorter: (a, b) => (a.deduction || 0) - (b.deduction || 0),
                    sortDirections: ["descend", "ascend"],
                },
				{
                    title: "Discounts",
                    key: "discounts",
                    scopedSlots: { customRender: "discounts" },
                    sorter: (a, b) => ((a.total - (a.subTotal + a.tax - (a.deduction || 0))) || 0) - ((b.total - (b.subTotal + b.tax - (b.deduction || 0))) || 0),
                    sortDirections: ["descend", "ascend"],
                },
				{
                    title: "Tax",
                    key: "tax",
                    scopedSlots: { customRender: "tax" },
                    sorter: (a, b) => (a.tax || 0) - (b.tax || 0),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Total",
                    key: "total",
                    scopedSlots: { customRender: "total" },
                    sorter: (a, b) => (a.total || 0) - (b.total || 0),
                    sortDirections: ["descend", "ascend"],
                },
				{
					title: "Payment Mode",
					key: "paymentMode",
					scopedSlots: { customRender: "paymentMode" },
					sorter: (a, b) =>
						a.paymentMode < b.paymentMode
							? -1
							: a.paymentMode > b.paymentMode
								? 1
								: 0,
				},
                {
                    title: "Status",
                    key: "status",
                    scopedSlots: { customRender: "status" },
                    sorter: (a, b) => {
                        const aa = (a.paid ? "PAID" : "UNPAID") || "";
                        const bb = (b.paid ? "PAID" : "UNPAID") || "";
                        return aa < bb ? -1 : aa > bb ? 1 : 0;
                    },
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Refund Amount",
                    key: "refundTotal",
                    scopedSlots: { customRender: "refundTotal" },
                    sorter: (a, b) => {
                        const aRefund = a.refunds.reduce(
                            (a, b) => a + b.amount,
                            0
                        );
                        const bRefund = b.refunds.reduce(
                            (a, b) => a + b.amount,
                            0
                        );
                        return aRefund - bRefund;
                    },
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Refund Status",
                    key: "refundStatus",
                    scopedSlots: { customRender: "refundStatus" },
                    sorter: (a, b) => {
                        const aa = a.refundStatus || "";
                        const bb = b.refundStatus || "";
                        return aa < bb ? -1 : aa > bb ? 1 : 0;
                    },
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                },
            ],
            trainingColumns: [
                {
                    title: "Description",
                    key: "description",
                    scopedSlots: { customRender: "description" },
                    sorter: (a, b) =>
                        a.description < b.description
                            ? -1
                            : a.description > b.description
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Supplied Date",
                    key: "dateTime",
                    scopedSlots: { customRender: "dateTime" },
                    sorter: (a, b) =>
                        (moment(a.dateTime).format("x") || 0) -
                        (moment(b.dateTime).format("x") || 0),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Duration",
                    key: "duration",
                    scopedSlots: { customRender: "duration" },
                    sorter: (a, b) =>
                        a.rawValue < b.rawValue
                            ? -1
                            : a.rawValue > b.rawValue
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Remaining Hours",
                    key: "remainingHours",
                    scopedSlots: { customRender: "remainingHours" },
                    sorter: (a, b) => (a.value || 0) - (b.value || 0),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Trainer",
                    key: "trainer",
                    scopedSlots: { customRender: "trainer" },
                    sorter: (a, b) =>
                        a.trainers.length < b.trainers.length
                            ? -1
                            : a.trainers.length > b.trainers.length
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Trainee",
                    key: "trainee",
                    scopedSlots: { customRender: "trainee" },
                    sorter: (a, b) =>
                        a.trainees.length < b.trainees.length
                            ? -1
                            : a.trainees.length > b.trainees.length
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                },
            ],
			setUpFeeColumns: [
                {
                    title: "Description",
                    key: "description",
                    scopedSlots: { customRender: "description" },
                    sorter: (a, b) =>
                        a.description < b.description
                            ? -1
                            : a.description > b.description
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Performed Date",
                    key: "dateTime",
                    scopedSlots: { customRender: "dateTime" },
                    sorter: (a, b) =>
                        (moment(a.dateTime).format("x") || 0) -
                        (moment(b.dateTime).format("x") || 0),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Duration",
                    key: "duration",
                    scopedSlots: { customRender: "duration" },
                    sorter: (a, b) =>
                        a.rawValue < b.rawValue
                            ? -1
                            : a.rawValue > b.rawValue
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
				{
                    title: "Rate",
                    key: "rate",
                    scopedSlots: { customRender: "rate" },
                    sorter: (a, b) => (a.rate || 0) - (b.rate || 0),
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Amount",
                    key: "amount",
                    scopedSlots: { customRender: "amount" },
                    sorter: (a, b) => (a.amount || 0) - (b.amount || 0),
                    sortDirections: ["descend", "ascend"],
                },
				{
                    title: "Performed By",
                    key: "trainer",
                    scopedSlots: { customRender: "trainer" },
                    sorter: (a, b) =>
                        a.trainers.length < b.trainers.length
                            ? -1
                            : a.trainers.length > b.trainers.length
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Requested By",
                    key: "trainee",
                    scopedSlots: { customRender: "trainee" },
                    sorter: (a, b) =>
                        a.trainees.length < b.trainees.length
                            ? -1
                            : a.trainees.length > b.trainees.length
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                }
            ],
			paymentsColumns: [
				{
					title: "Amount",
					key: "amount",
					scopedSlots: { customRender: "amount" },
					sorter: (a, b) => a.amount - b.amount,
				},
				{
					title: "Payment Date",
					key: "paymentDate",
					scopedSlots: { customRender: "paymentDate" },
					sorter: (a, b) => a.paymentDate - b.paymentDate,
				},
				{
					title: "Payment Mode",
					key: "paymentMode",
					scopedSlots: { customRender: "paymentMode" },
					sorter: (a, b) =>
						a.paymentMode < b.paymentMode
							? -1
							: a.paymentMode > b.paymentMode
								? 1
								: 0,
				},
				{
					title: "Note",
					key: "note",
					scopedSlots: { customRender: "note" },
					sorter: (a, b) =>
						a.note < b.note
							? -1
							: a.note > b.note
								? 1
								: 0,
				},
				{
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                },
			],
			refundColumns: [
				{
					title: "Amount",
					key: "amount",
					scopedSlots: { customRender: "amount" },
					sorter: (a, b) => a.amount - b.amount,
				},
				{
					title: "Refund Date",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: (a, b) => a.createdAt - b.createdAt,
				},
				{
					title: "Refund Reason",
					key: "reason",
					scopedSlots: { customRender: "reason" },
					sorter: (a, b) =>
						a.reason < b.reason
							? -1
							: a.reason > b.reason
								? 1
								: 0,
				},
			],
			trainersPerformers: [],
			requesterUserSearchResult: [],
            trainings: [],
            setupFees: [],
            selectedFeatureLogId: null,
            featureLog: {
                featureName: "training_hours",
                description: "",
                dateTime: null,
				rate: null,
                rawValue: null,
                trainers: [],
                trainees: [],
            },
			measurementSettings: {
                frontageSize: "ft",
                generalMeasurement: "ft",
            },
			showAPIKey: false,
			instanceAPIKey: "",
			selectedPaymentMethod: null,
			selectedInvoiceIds: [],
			userRoles: {
				'bh_admin' : 'Admin',
				'bh_agent' : 'VIP Realtor',
				'bh_agent_manager': 'VIP Broker Manager'
			}
        };
    },

    computed: {
        ...mapState(["project", "allUsers", "promoCodes"]),

        projectStatus() {
            if (this.project.deleted) return "Deleted";
            if (this.project.susspended) return "Locked";
            return "Active";
        },

        rules() {
            return {
                name: [
                    {
                        required: true,
                        message: "Please enter your name",
                        trigger: "change",
                    },
                ],
				billingName: [
                    {
                        required: true,
                        message: "Please enter billing name",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "Please enter your address",
                        trigger: "change",
                    },
                ],
                city: [
                    {
                        required: true,
                        message: "Please enter your city",
                        trigger: "change",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "Please select your country",
                        trigger: "change",
                    },
                ],
                postalCode: [
                    {
                        required: true,
                        message: "Please enter your postal code",
                        trigger: "change",
                    },
                ],
                region: [
                    {
                        required: true,
                        message: "Please enter your region",
                        trigger: "change",
                    },
                ],
            };
        },

        packageStyle() {
            return {
                "grid-template-columns": `repeat(${this.packages.length}, minmax(0, 1fr))`,
                padding:
                    this.packages.length === 1
                        ? "0 33%"
                        : this.packages.length === 2
                        ? "0 16.5%"
                        : "0 0",
            };
        },

        addOnsPackages() {
			return this.extraPackages.filter((x) => x.type === "addon" || x.itemCode === "AD-SMS");
        },

        extraPackages() {
            return this.packageExtras.map((x) => {
                const find = this.project.selectedPackageExtras && this.project.selectedPackageExtras.find(
                    (y) => y.package_extra.id === x.id
                );
                return {
                    ...x,
                    selected: find ? true : false,
                };
            });
        },

        additionalPackages() {
            if (!this.instancePriceDetails) {
                return [];
            }
            let result = JSON.parse(JSON.stringify(this.instancePriceDetails.additional || [])) ;

            if (this.instancePriceDetails.featureBlocks && this.instancePriceDetails.featureBlocks.length) {
                this.instancePriceDetails.featureBlocks.forEach((x) => {
                    if (x.name === "Training hours") {
                        result.push({
                            ...x,
                            name: "Training Hours",
                            quantity: x.quantity,
                        });
                    } else {
						result.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
                    }
                });
            }

            return result;
        },

        filterUsers() {
			if (this.changeBillingOwnerModal) {
				return this.users.filter((u) => u.role !== "Billing Owner" && u.role !== "Project & Billing Owner");
			} else if (this.changeProjectOwnerModal) {
				return this.users.filter((u) => u.role !== "Project Owner" && u.role !== "Project & Billing Owner");
			}
			return this.users;
		},

		paymentMethodList() {
            let options = [];
            if (
                this.paymentMethods &&
                this.paymentMethods.length != 0 &&
                this.switchCardModal &&
                this.switchCardModal.card &&
                Object.keys(this.switchCardModal.card).length != 0
            ) {
                this.paymentMethods.forEach((pay) => {
                    let obj = {};
                    if (pay.details.ccBrand == "visa") obj.name = "Visa";
                    else if (pay.details.ccBrand == "mc")
                        obj.name = "Mastercard";
                    else if (pay.details.ccBrand == "amex")
                        obj.name = "American Express";
                    obj.label = `${obj.name} ${this.getCardNumber(
                        pay.details.cardNo
                    )}`;
                    obj.value = pay.id;
                    if (obj.value != this.switchCardModal.card.id)
                        options.push(obj);
                });
                let newCard = {
                    label: "Add credit or debit card",
                    value: "newCard",
                };
                options.push(newCard);
            }
            return options;
        },

		supportRequestType() {
			const packageExtras = this.project && this.project.selectedPackageExtras || []
			const videoChatSupport = packageExtras.find(p => p.package_extra && (p.package_extra.identifier === 'online_video_chat_support'))

			if (videoChatSupport) {
				return "Ticket & Video Chat Support";
			} else {
				return "Ticket Support";
			}
		},

		remainingAmount() {
			if (this.selectedInvoice && this.selectedInvoice.paymentMode === 'offline') {
				let total = this.selectedInvoice.total
				if (this.selectedInvoice.payments && this.selectedInvoice.payments.length) {
					const totalPayments = this.selectedInvoice.payments.reduce((total, nextValue) => total + nextValue.amount, 0)
					total = total - totalPayments;
				}
				return total.toLocaleString("en-US", { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })
			}
			return 0;
		},

		lastInvoice() {
			let invoices = (this.invoices.length ? this.invoices : this.project.invoices) || []
			invoices = invoices.sort((a, b) => b.validUntil - a.validUntil)
			return invoices && invoices[0];
		},

		currentAddOns() {
			return (this.project && this.project.selectedPackageExtras || []).sort((a, b) => a.name.localeCompare(b.name))
		},

		lotDoorPackage() {
			const found = this.packageExtras.find((x) => x.identifier === "lot_door_fee");
			return found || null;
		},

		addOnNextButtonDisabled() {
			let isDisabled = true;

			if (this.selectedExtraPackages['addOns']) {
				const selectedAddOns = this.selectedExtraPackages['addOns'].some(a => a.selected)
				if (selectedAddOns || +this.lotsOrDoors || +this.trainingHourBlocks) {
					isDisabled = false;
				}
			} else if (+this.lotsOrDoors || +this.trainingHourBlocks) {
				isDisabled = false;
			}
			return isDisabled
		}
    },

    created() {
		console.log('created')
		if (!this.project || !this.project.id) {
			return this.$router.push('/');
		}

		this.instance = {
			...this.project,
		};

		this.currentPaymentMethod = this.project.paymentMethod || { };
		this.instanceAPIKey = (this.project.api && this.project.api.key) || null;
		this.paymentMode = this.lastInvoice && this.lastInvoice.paymentMode || 'online'
		this.selectedPaymentMethod = this.currentPaymentMethod && this.currentPaymentMethod.id || null;

		this.getInstanceSettings();
        this.getUsersDetails();
        this.getUserInstances();
        this.getFeatureLogs();
		// this.$store.dispatch('FETCH_ALL_USERS')
        this.getInvoiceDetails();

		if (this.instance.billingOwner && this.instance.billingOwner.id) {
			this.fetchPaymentDetails(this.instance.billingOwner.id)
		}

		this.$store.dispatch('FETCH_ALL_PROMO_CODES')
    },

    methods: {
        ...mapMutations(["SET_USER", "SET_USERS", "SET_PROJECT"]),

		handleRequesterSearch(input){
			console.log('Handle Search');
			if (input.length < 3) {
				return (this.timerId = undefined);
			}
			const makeAPICall = () => {
				this.updateRequesterList(input);
			};

			if (this.timerId) clearTimeout(this.timerId);

			this.timerId = setTimeout(function () {
				makeAPICall();
				this.timerId = undefined;
			}, 400);
		},
		async updateRequesterList(input) {
			try {
				let { data } = await this.$api.get(
					`/super-admin/users?_q=${input}&limited=true`
				);
				console.log("data", data);
				this.requesterUserSearchResult = data.map(({ name, id, email }) => ({
					label: name,
					value: id,
					email: email,
				}));
				console.log("THIS SEARCH RESULT", this.requesterUserSearchResult, data);
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },
        goBack() {
            this.$router.go(-1);
        },

		copy(value) {
			navigator.clipboard.writeText(value);
			this.$message.success("Copied");
		},

		onSelectChange(selectedRowKeys) {
	      this.selectedInvoiceIds = selectedRowKeys;
	    },

		downloadSetupFees() {
			console.log('Downloading Setup Fees...', this.setupFees);
			if (this.setupFees.length) {
				const columns = ['Description', 'Performed Date', 'Duration', 'Rate', 'Amount', 'Performed By', 'Requested By'];
				let csv = columns.map(col => `"${col}"`).join(',') + '\n';

				this.setupFees.forEach(fee => {
					let description = (fee.description || 'N/A').replace(/\r?\n|\r/g, ' '); // Replace line breaks
					let performedDate = !fee.dateTime ? "N/A" : this.getDateTime(fee.dateTime);
					let duration = fee.rawValue.replaceAll("h", "") + "h";
					let rate = '$' + fee.rate;
					let amount = '$' + `${fee.amount && fee.amount.toLocaleString("en-US", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 }) || 0} (${this.instance.currency || "USD"})`;
					let trainers = fee.trainers.map(trainer => `${trainer.firstName} ${trainer.lastName} (${trainer.email})`).join(', ');
					let trainees = fee.trainees.map(trainee => `${trainee.firstName} ${trainee.lastName} (${trainee.email})`).join(', ');

					csv += `"${description}","${performedDate}","${duration}","${rate}","${amount}","${trainers}","${trainees}"\n`;
				});

				const anchor = document.createElement("a");
				anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.instance.name}_Setup_Fees.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
		convertToOfflinePayment() {
			if (!this.selectedInvoiceIds.length) {
				return
			}
			let self = this;
			this.$confirm({
				title: `Offline Payment Mode Conversion`,
				content: (h) => <div>Do you want to convert selected <strong> {this.selectedInvoiceIds.length} Invoices </strong> to Offline Payment Mode?</div>,
				okText: "SUBMIT",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.post(`/super-admin/invoices/convert-invoices-to-offline`, {
						invoiceIds: self.selectedInvoiceIds
					}).then(() => {
						self.loading = false;
						self.$message.success(`Successfully converted selected valid invoices to offline payment mode.`);
						self.selectedInvoiceIds = [];
						self.getInvoiceDetails();
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while converting offline invoice payment mode. Please try again.`))
						}
					})
				}
			});
		},

		bulkVoidInvoices() {
			let invoices = [];

			if (!this.selectedInvoiceIds.length) {
				return
			}
			invoices = this.invoices.filter(invoice => this.selectedInvoiceIds.includes(invoice.id) && !invoice.paid)

			if (!invoices.length) {
				return
			}

			let self = this;
			this.$confirm({
				title: `Bulk Void Unpaid Invoices`,
				content: (h) => <div>Are you sure you want to void these invoices <strong>{invoices.map(i => `#${i.invoiceNumber}`).join(', ')}</strong>?</div>,
				okText: "VOID",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.post(`/super-admin/invoices/bulk-delete`, { invoiceIds: invoices.map(i => i.id) }).then(() => {
						self.loading = false;
						self.$message.success(`Successfully voided selected invoices.`);
						self.selectedInvoiceIds = [];
						self.getInvoiceDetails();
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while voiding invoices. Please try again.`))
						}
					})
				}
			});
		},

		voidInvoice(invoice) {
			if (invoice.paid) {
				return
			}
			let self = this;
			this.$confirm({
				title: `Void Unpaid Invoice`,
				content: (h) => <div>Are you sure you want to void this <strong>#{invoice.invoiceNumber}</strong> invoice?</div>,
				okText: "VOID",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.delete(`/super-admin/invoices/${invoice.id}`).then(() => {
						self.loading = false;
						self.$message.success(`Successfully voided selected invoice.`);
						self.getInvoiceDetails();
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while voiding invoice. Please try again.`))
						}
					})
				}
			});
		},

		async getInstanceSettings() {
			try {
				const { data } = await this.$api.get(`/settings/${this.instance.id}`);
				if (data.instance && data.instance.options && data.instance.options.measurement) {
					this.measurementSettings.frontageSize = data.instance.options.measurement.frontageSize;
					this.measurementSettings.generalMeasurement = data.instance.options.measurement.generalMeasurement;
				}
			} catch (err) {
				// catch the exception
			}
		},

        async onDelete() {
            this.confirmLoading = true;

            try {
                await this.$api.delete(
                    `/instances/${this.project.id}?downloadData=${this.downloadData}`
                );
                this.deleteModal = false;
                this.$router.push("/projects");
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while deleting project. Please try again!"))
				}
            } finally {
                this.confirmLoading = false;
            }
        },

        changePlan() {
            if (this.project.projectType && this.project.productType) {
                this.resetData();
                this.changeInPlan = true;
                this.packages = [];
                this.packageExtras = [];

                const promises = [
                    this.$api.get(
                        `/packages?projectType=${
                            this.project.projectType
                        }&productType=${this.project.productType}&currency=${
                            this.project.currency || "USD"
                        }&includeMaintenance=true`
                    ),
                    this.$api.get(
                        `/package-extras?currency=${
                            this.project.currency || "USD"
                        }`
                    ),
                ];

                Promise.all(promises)
                    .then(([packages, packageExtras]) => {
                        this.packages = packages.data;
                        this.packageExtras = packageExtras.data;

                        const lot_door_fee = this.packageExtras.find(
                            (pe) => pe.identifier === "lot_door_fee"
                        );

                        this.packages.forEach((p) => {
                            this.selectedExtraPackages[p.id] = lot_door_fee
                                ? [lot_door_fee]
                                : [];
                        });
						this.packages.sort((a, b) => b.price - a.price);
                    })
                    .catch((err) => {
                        if (err?.response?.status !== 400) {
							this.$message.error(this.$err(err, `Error while fetching packages. Please Try Again!`))
						}
                    });
            } else {
                this.$message.error(
                    "No valid plan details found. Please contact Administrator!"
                );
            }
        },

        editUser(user) {
            this.SET_USER(user);
            this.$router.push(`/users/${user.id}`);
        },

        viewAll() {},

        async getUsersDetails() {
            this.loading = true;
            try {
                let { data } = await this.$api.get(
                    "/super-admin/instances/" + this.project.id + "/users"
                );
                this.users = data.users.map((x) => {
                    let role = this.userRoles[x.role.name] || x.role.name;
					if (this.project.owner && this.project.owner.id === x.user.id) {
						role = "Project Owner";
						if (this.project.owner.id === this.project.billingOwner.id) {
							role = "Project & Billing Owner";
						}
					} else if (this.project.billingOwner && this.project.billingOwner.id === x.user.id) {
						role = "Billing Owner";
					}
                    return {
                        ...x.user,
                        role: role,
                    };
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching members list. Please try again!"))
				}
            }

            this.loading = false;
        },

        async getUserInstances() {
            this.loading = true;
            try {
                let { data } = await this.$api.get(
                    `/super-admin/instances?_limit=-1&_where[_or][0][owner]=${this.project.owner.id}&_where[_or][1][0][users]=${this.project.owner.id}&_where[_or][1][1][users.role.name]=bh_admin`
                );
                this.instances = data.filter(
                    (instance) => instance.id !== this.project.id
                );
                if (
                    this.project.allChild &&
                    this.project.allChild.length !== 0
                ) {
                    this.children = this.project.allChild.map(
                        (child) => child.id
                    );
                } else {
                    this.children = [];
                }
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching instance list. Please try again!"))
				}
            }

            this.loading = false;
        },

        submit() {
			let { name, marketingName, billingName, promoCode, address, city, country, postalCode, region, currency } = this.instance;

			if(!promoCode) promoCode = null;

			let sendObj = { name, marketingName, billingName, promoCode, address, city, country, postalCode, region, currency };

			if (this.children.length !== 0) {
				sendObj.children = this.children;
			} else {
				sendObj.children = [];
			}

            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
					this.loading = true;
                    let url = `/super-admin/instances/${this.instance.id}`;
					this.saveInstanceSettings();
                    this.$api
                        .put(url, sendObj)
                        .then(() => {
                            this.$message.success(
                                "You have successfully Updated your Project."
                            );
                            this.$router.push("/projects");
                        })
                        .catch((err) => {
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, `Error while updating project details. Please try again.`));
							}
                        }).finally(() => this.loading = false);
                } else {
                    return false;
                }
            });
        },

		saveInstanceSettings() {
			this.$api.post(
				`/settings/${this.instance.id}`,
				{
					options: {
						measurement: {
							frontageSize: this.measurementSettings.frontageSize,
							generalMeasurement: this.measurementSettings.generalMeasurement
						}
					}
				}
			).then(() => { }).catch((err) => console.error(err));
		},

        next() {
            if (this.current === 1 && this.selectedPackageId) {
                this.fetchPriceDetails();
                this.current++;
                return;
            } else if (this.current === 2) {
                this.loadModal = true;

                const instanceParams = {
                    packageId: this.selectedPackageId,
                    promoCode: this.couponCode,
                    numberOfTrainingHoursBlock: this.trainingHourBlocks
                        ? parseInt(this.trainingHourBlocks)
                        : 0,
                    packageExtras: this.selectedExtraPackages[
                        this.selectedPackageId
                    ]
                        .map((extras) => {
                            if (extras.selected) {
                                return {
                                    id: extras.id,
                                    quantity: 1,
                                };
                            } else if (
                                this.lotsOrDoors &&
                                extras.identifier === "lot_door_fee"
                            ) {
                                return {
                                    id: extras.id,
                                    quantity: parseInt(this.lotsOrDoors),
                                };
                            } else {
                                return null;
                            }
                        })
                        .filter((pe) => pe),
					paymentMode: this.paymentMode
                };

				if(this.paymentMode === 'online') {
					instanceParams.paymentMethod = this.selectedPaymentMethod
				}

                this.$api
                    .put(
                        `/instances/${this.project.id}/change-plan`,
                        instanceParams
                    )
                    .then(() => {
                        this.$message.success(
                            "Project was successfully updated."
                        );
                        this.current--;
                        this.changeInPlan = false;
                        this.loadModal = false;
                        this.resetData();
                        this.goBack();
                    })
                    .catch((err) => {
                        this.loadModal = false;
						if (err?.response?.status !== 400) {
							this.$message.error(this.$err(err, `Error occurred while updating plan. Please try again!`));
						}
                    });
            }
        },

        fetchPriceDetails() {
            const instanceParams = {
				currency: this.project.currency,
                instanceId: this.project.id,
                packageId: this.selectedPackageId,
                promoCode: this.couponCode,
				partner: (this.project.partner && this.project.partner.id) || null,
                numberOfTrainingHoursBlock: this.trainingHourBlocks
                    ? parseInt(this.trainingHourBlocks)
                    : 0,
                packageExtras: this.selectedExtraPackages[
                    this.selectedPackageId
                ]
                    .map((extras) => {
                        if (extras.selected) {
                            return {
                                id: extras.id,
                                quantity: 1,
                            };
                        } else if (
                            this.lotsOrDoors &&
                            extras.identifier === "lot_door_fee"
                        ) {
                            return {
                                id: extras.id,
                                quantity: parseInt(this.lotsOrDoors),
                            };
                        } else {
                            return null;
                        }
                    })
                    .filter((pe) => pe),
            };
            this.validCouponCode = this.couponCode ? "validating" : "";

            this.$api
                .post("/instances/price", instanceParams)
                .then(({ data }) => {
                    this.instancePriceDetails = data;
                    if (this.couponCode) {
                        data.discounts.forEach((discount) => {
                            if (discount.code === this.couponCode) {
                                this.validCouponCode = "success";
                            }
                        });
                        if (this.validCouponCode === "validating") {
                            this.validCouponCode = "error";
                            this.$message.error("Invalid coupon code.");
                        } else if (this.validCouponCode === "success") {
                            this.$message.success(
                                "Coupon code applied successfully."
                            );
                        }
                    }
                })
                .catch((err) => {
                    if (err?.response?.data?.statusCode === 400) {
                        this.validCouponCode = this.couponCode ? "error" : "";
                        this.$message.error(err.response.data.message);
                    }
                });
        },

        updatePaymentMethod(paymentMethodDetails) {
            let expiry = valid.expirationDate(paymentMethodDetails.expiry);
            let sendCardOBJ = JSON.parse(JSON.stringify(paymentMethodDetails));
            let { month, year } = expiry;
            sendCardOBJ.expiry = `${month}/${year}`;

            this.loadModal = true;
            this.confirmLoading = true;
			this.paymentMethodModal.load = true;
			let self = this;

			if (this.paymentMethodModal.action === "add") {
				this.$api
					.post("/payment-methods", {
						details: sendCardOBJ,
						default: sendCardOBJ.default || false
					})
					.then(({ data }) => {
						if (data.success === false) {
							let errorMessage = data.result.message;
							errorMessage = errorMessage.split("\n");
							errorMessage.forEach((mess) => {
								self.$message.error(mess);
							});
						} else {
							self.$message.success('Payment method added successfully!');
						}
						self.paymentMethods.push(data);
						self.resetPaymentMethodModalData();
					})
					.catch((err) => {
						self.loadModal = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `Error while creating payment method. Try Again!`));
						}
					});
			} else if (this.paymentMethodModal.action === "edit") {
				this.$api
                .put(
                    `/super-admin/payment-methods/${this.paymentMethodModal.paymentMethodId}`,
                    {
                        details: sendCardOBJ,
						default: sendCardOBJ.default || false
                    }
                )
                .then(({data}) => {
                    this.confirmLoading = false;
                    this.loadModal = false;

					if (data.success == false) {
                        let errorMessage = data.result.message;
                        errorMessage = errorMessage.split("\n");
                        errorMessage.forEach((mess) => {
                            self.$message.error(mess);
                        });
                    } else {
						this.$message.success(
	                        "payment method details updated successfully."
	                    );
                        self.paymentMethods.forEach((pm, index) => {
                            if (pm.id === data.id) {
                                self.paymentMethods[index] = data;
                            }
                        });
						if(!this.changeInPlan) {
							this.switchCardModal.visible = true;
							this.switchCardModal.card = data;
						}
						this.resetPaymentMethodModalData();
                    }
                })
                .catch((err) => {
                    this.confirmLoading = false;
                    this.loadModal = false;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while updating payment method. Please try again!`));
					}
                });
			}
        },

		async getTimeline() {
			try {
				const instanceId = this.project.id;
				const { data } = await this.$api.get(`timelines/${instanceId}/billing/${instanceId}`);
				this.generateTimelineData(data);
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while getting timeline details. Please try again!"))
				}
			}
		},

		generateTimelineData(activities) {
			let timeline = [];

			activities.forEach(activity => {
				if (activity.data && activity.data.action) {
					const id = activity.createdAt
					let invoice = activity.data.invoice;

					if (activity.data.payment) {
						invoice.payments = [activity.data.payment]
					}

					if (activity.data.refund) {
						invoice.refunds = [{...activity.data.refund, createdAt: activity.createdAt}]
					}

					if (!invoice.details.selectedPackageExtras) {
						invoice.details.selectedPackageExtras = []
					}

					if (!invoice.currency) {
						const found = this.invoices.find(i => i.id === invoice.id)
						if (found) {
							invoice.currency = found.currency
						}
					}

					if(invoice && invoice.details && invoice.details.featureBlocks){
						invoice.details.featureBlocks.forEach(
							(x) => {
								if (x.name === "Training hours") {
									const index = invoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
									if (index >= 0) {
										invoice.details.selectedPackageExtras[index] = {
											...x,
											name: "Training Hours",
											quantity: x.quantity,
										}
									} else {
										invoice.details.selectedPackageExtras.push(
											{
												...x,
												name: "Training Hours",
												quantity: x.quantity,
											}
										);
									}
								} else {
									invoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
								}
							}
						);
					}

					const actions = {
						instance_created: 'Project Created',
						instance_renew: 'Project Renew',
						package_updated: 'Project Package Updated',
						feature_charge: 'New Feature Charged',
						usage_invoice: 'New Usage Charged',
						payment_received: 'Payment Received',
						payment_updated: 'Payment Updated',
						payment_deleted: 'Payment Deleted',
						invoice_refunded: 'Invoice Refunded',
						addons_invoice: 'Addons Invoice',
					}

					timeline.push({
						id,
						date: this.textDate(activity.createdAt),
						title: actions[activity.data.action] || activity.data.action,
						invoice
					})
				}
			})

			this.timeline = timeline;

			if (this.timeline.length) {
				this.onActivitySelect(this.timeline[0])
			}
		},

		onActivitySelect(activity){
			this.selectedActivity = activity;
		},

		textDate(date){
            const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            let newDate = new Date(date)
			let month = monthNames[Number(newDate.getMonth())]
			return month + ' ' + newDate.getDate() + ', ' + newDate.getFullYear()
        },

        onCheckboxChange(event, extraPackage, packageId) {
            const obj = { ...extraPackage, selected: event.target.checked };

            const index = this.selectedExtraPackages[packageId].findIndex(
                (item) => item.id === obj.id
            );
            if (index === -1) {
                this.selectedExtraPackages[packageId].push(obj);
            } else {
                this.selectedExtraPackages[packageId][index] = obj;
            }
        },

		resetInvoicePaymentData() {
			this.invoicePaymentModal = {
				show: false,
				title: 'Add Offline Invoice Payment',
				okText: "SUBMIT",
				selectedInvoice: null,
				selectedInvoicePayment: null,
			}

			this.invoicePayment = {
				invoice: "",
				paymentDate: null,
				amount: 0,
				note: "",
				paymentMode: "check",
				metadata: {
					checkNumber: "",
					bankName: "",
					fromAccountNumber: "",
					toAccountNumber: "",
					checkDate: null,
					transferDate: null,
					receivedFrom: "",
					receivedBy: ""
				},
				instance: ""
			},

			this.bulkPaymentModal = {
				show: false,
				invoicePayment: {
					invoices: [],
					paymentDate: null,
					note: "",
					paymentMode: "check",
					metadata: {
						checkNumber: "",
						bankName: "",
						fromAccountNumber: "",
						toAccountNumber: "",
						checkDate: null,
						transferDate: null,
						receivedFrom: "",
						receivedBy: ""
					},
					instance: ""
				}
			}

			this.selectedInvoiceIds = [];
		},

		openInvoicePaymentModal(selectedInvoice, action = 'add', selectedInvoicePayment = {}) {
			this.invoicePayment.invoice = selectedInvoice.id;
			this.invoicePayment.instance = selectedInvoice.instance.id;

			let total = selectedInvoice.total
			if (selectedInvoice.payments && selectedInvoice.payments.length) {
				const totalPayments = selectedInvoice.payments.reduce((total, nextValue) => total + nextValue.amount, 0)
				total = total - totalPayments;
			}

			this.invoicePayment.amount = total;

			this.invoicePayment = {
				...this.invoicePayment,
				...selectedInvoicePayment
			}

			this.invoicePayment.paymentDate = action === 'add' ? moment() : moment(selectedInvoicePayment.paymentDate)

			this.invoicePaymentModal = {
				show: true,
				action: action,
				title: action === 'add' ? 'Add Offline Invoice Payment' : 'Update Offline Invoice Payment',
				okText: action === 'add' ? "SUBMIT" : "UPDATE",
				selectedInvoice: selectedInvoice,
				selectedInvoicePayment: action === 'add' ? selectedInvoicePayment : null,
			}
		},

		offlineInvoicePayment() {
			this.$refs.invoicePayment.validate((validForm) => {
				if (validForm) {
					this.confirmLoading = true;
					let url = this.invoicePaymentModal.action === 'add' ? `/invoice-payments` : `/invoice-payments/${this.invoicePayment.id}`
					let payload = {...this.invoicePayment};
					payload.amount = +payload.amount
					payload.paymentDate = +moment(payload.paymentDate).format("x");
					this.$api[this.invoicePaymentModal.action === 'add' ? 'post' : 'put'](url, payload)
						.then(() => {
							this.confirmLoading = false;
							this.$message.success(
								`Successfully ${this.invoicePaymentModal.action === 'add' ? 'added' : 'update'} offline payment record.`
							);
							this.resetInvoicePaymentData();
							this.getInvoiceDetails();
							this.editingInvoice = false;
                            this.selectedInvoice = {};
						})
						.catch((err) => {
							this.confirmLoading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, `An error occurred while paying for offline invoice. Please try again.`));
							}
						});
				}
			});
		},

		async getOfflineInvoicePayments() {
			try {
				let { data } = await this.$api.get(`invoice-payments?instance=${this.instance.id}`);
				this.OfflineInvoices = data;
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching offline invoice payments. Please try again.`))
				}
			}
		},

		async deleteInvoicePayment(invoicePayment) {
			let self = this;
			this.$confirm({
				title: `Delete Offline Invoice Payment`,
				content: (h) => <div>Do you want to delete this Offline Invoice Payment?</div>,
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.loading = true;
					self.$api.delete(`/invoice-payments/${invoicePayment.id}`).then(() => {
						self.loading = false;
						self.$message.success(`Successfully deleted selected offline payment record.`);
						self.getInvoiceDetails();
						self.editingInvoice = false;
                        self.selectedInvoice = {};
					}).catch((err) => {
						self.loading = false;
						if (err?.response?.status !== 400) {
							self.$message.error(self.$err(err, `An error occurred while deleting offline invoice payments. Please try again.`))
						}
					})
				}
			});
		},

        makePayment(invoice) {
			if (invoice.paymentMode === 'offline') {
				return this.openInvoicePaymentModal(invoice, 'add')
			}
            this.$message.info("Payment initiated.");
            this.$api
                .get(`/invoices/tryAgain/${invoice.id}`)
                .then(({ data }) => {
                    if (data && data.success) {
                        this.$message.success("Payment done successfully.");
                        let index = this.invoices.findIndex(
                            (x) => x.id === invoice.id
                        );
                        if (index !== -1) {
                            this.invoices[index].paid = true;
                        }
                    } else {
                        this.$message.error(
                            `Payment failed. ${
                                (data &&
                                    data.message &&
                                    data.message.message) ||
                                ""
                            }`
                        );
                    }
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, `Error while making payment for the invoice. Please try again!`));
					}
                });
        },

        refundPayment() {
            this.confirmLoading = true;
            this.$api
                .post(`/invoices/${this.selectedInvoice.id}/refund`, {
                    reason: this.refundReason,
                    amount: this.refundAmount,
                })
                .then(({ data }) => {
                    if (this.editingInvoice) {
						this.editInvoice(data.invoice)
                    }
                    this.getInvoiceDetails();
                    this.confirmLoading = false;
                    this.refundModal = false;
                    this.$message.success("Refund initiated successfully");
                })
                .catch((err) => {
                    this.confirmLoading = false;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while initiating refund for the invoice. Please try again!"));
					}
                });
        },

        editInvoice(invoice) {
            this.editingInvoice = true;
            this.selectedInvoice = JSON.parse(JSON.stringify(invoice));
			if (this.selectedInvoice.details && this.selectedInvoice.details.featureBlocks && this.selectedInvoice.details.featureBlocks.length) {
				if (!this.selectedInvoice.details.selectedPackageExtras) {
					this.selectedInvoice.details.selectedPackageExtras = []
				}
				this.selectedInvoice.details.featureBlocks.forEach(
					(x) => {
						if (x.name === "Training hours") {
							const index = this.selectedInvoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
							if (index >= 0) {
								this.selectedInvoice.details.selectedPackageExtras[index] = {
									...x,
									name: "Training Hours",
									quantity: x.quantity,
								}
							} else {
								this.selectedInvoice.details.selectedPackageExtras.push(
									{
										...x,
										name: "Training Hours",
										quantity: x.quantity,
									}
								);
							}
						} else {
							this.selectedInvoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
						}
					}
				);
			}

			if (this.timeline.length) {
				this.onActivitySelect(this.timeline[0])
			}
        },

		printInvoice(invoice) {
			let selectedInvoice = JSON.parse(JSON.stringify(invoice));
			if (selectedInvoice.details && selectedInvoice.details.featureBlocks && selectedInvoice.details.featureBlocks.length) {
				if (!selectedInvoice.details.selectedPackageExtras) {
					selectedInvoice.details.selectedPackageExtras = []
				}
				selectedInvoice.details.featureBlocks.forEach(
					(x) => {
						if (x.name === "Training hours") {
							const index = selectedInvoice.details.selectedPackageExtras.findIndex(p => p.name === "Training Hours");
							if (index >= 0) {
								selectedInvoice.details.selectedPackageExtras[index] = {
									...x,
									name: "Training Hours",
									quantity: x.quantity,
								}
							} else {
								selectedInvoice.details.selectedPackageExtras.push(
									{
										...x,
										name: "Training Hours",
										quantity: x.quantity,
									}
								);
							}
						} else {
							selectedInvoice.details.selectedPackageExtras.push({ ...x, quantity: x.quantity ? x.quantity : (x.value && Number(x.value)) });
						}
					}
				);
			}

			this.invoiceToDownload = selectedInvoice
		},

        getDate(date) {
            return moment(date).format("DD/M/YYYY");
        },

        getDateTime(date) {
            return moment(date).format("DD/M/YYYY hh:mm A");
        },

        getHours(date) {
            return moment(date).format("HH");
        },

        async getInvoiceDetails() {
            this.loading = true;
            try {
                let { data } = await this.$api.get(
                    `/super-admin/invoices?instance=${this.project.id}&_limit=-1`
                );
                this.invoices = data;
				this.getTimeline();
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching invoice list. Please try again!"))
				}
            }
            this.loading = false;
        },

		async updateBillingOwner() {
            this.confirmLoading = true;
            try {
                await this.$api.post(
                    `/super-admin/instances/${this.project.id}/change-billing-owner`,
                    {
                        ...this.updatedBillingOwner,
                    }
                );
                this.$message.success("Billing Owner details updated successfully");
                this.changeBillingOwnerModal = false;
                this.goBack();
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while updating billing owner details. Please try again!"))
				}
            }
            this.confirmLoading = false;
        },

        async updateProjectOwner() {
            this.confirmLoading = true;
            try {
                await this.$api.post(
                    `/super-admin/instances/${this.project.id}/change-owner`,
                    {
                        ...this.updatedProjectOwner,
                    }
                );
                this.$message.success("Project Owner details updated successfully");
                this.changeProjectOwnerModal = false;
                this.goBack();
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while updating project owner details. Please try again!"))
				}
            }
            this.confirmLoading = false;
        },

        async fetchPaymentDetails(userId) {
            this.noPaymentMethodSetup = false;
            this.loading = true;
            try {
                let { data } = await this.$api.get(
                    `/super-admin/payment-methods?user=${userId}&_limit=-1`
                );
                this.paymentMethods = data;
                this.noPaymentMethodSetup = !data.length;
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching payment list of the selected user. Please try again!"))
				}
            }
            this.loading = false;
        },

        async getFeatureLogs() {
            try {
                let { data } = await this.$api.get(
                    `/instance-feature-logs/${this.project.id}?featureName_ne=email_sends&_limit=-1`
                );
                this.trainings = data.filter(
                    (t) => t.featureName === "training_hours"
                );
				this.setupFees = data.filter(
                    (t) => t.featureName === "setup_fee"
                );
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching feature log list. Please try again!"))
				}
            }
        },

        addNewFeatureLog() {
            this.$refs.featureLog.validate((validForm) => {
                if (validForm) {
                    this.confirmLoading = true;

                    if (typeof this.featureLog.rawValue === "string") {
                        this.featureLog.rawValue =
                            this.featureLog.rawValue.replaceAll("h", "");
                    }
                    this.featureLog.rawValue = this.featureLog.rawValue + "h";

					if (this.featureLog.featureName === "setup_fee") {
						this.featureLog.rate = Number(this.featureLog.rate)
					}

                    if (!this.selectedFeatureLogId) {
                        this.$api
                            .post(
                                `/instance-feature-logs/${this.project.id}`,
                                this.featureLog
                            )
                            .then(() => {
                                this.confirmLoading = false;
                                this.resetFeatureLogData();

                                this.$message.success(
                                    "Successfully Added new record"
                                );
                                this.getFeatureLogs();
								this.getInvoiceDetails();
                            })
                            .catch((err) => {
                                this.confirmLoading = false;
								if (err?.response?.status !== 400) {
									this.$message.error(this.$err(err, "Error while adding record. Please try again!"));
								}
                            });
                    } else {
                        this.editFeatureLog();
                    }
                }
            });
        },

        deleteFeatureLog(featureLog, type = 'training') {
			let self = this;
			this.$confirm({
				title: `Delete ${type === 'training' ? 'Training' : ' Setup Fee'}`,
				content: (h) => <div>Do you want to delete this {type === 'training' ? 'Training' : ' Setup Fee'}?</div>,
				okText: "DELETE",
				okType: "danger",
				cancelText: "CANCEL",
				centered: true,
				onOk() {
					self.$api.delete(
						`/instance-feature-logs/${self.project.id}/${featureLog.id}`).then(() => {
							self.getFeatureLogs();
						}).catch((err) => {
							if (err?.response?.status !== 400) {
								self.$message.error(self.$err(err, "An error occurred while deleting record. Please try again."));
							}
						})
				}
			});
		},

        openFeatureLogEdit(featureLog, type = 'training') {
            this.featureLog = {
                featureName: type === 'training' ? "training_hours" : "setup_fee",
                description: featureLog.description,
                dateTime: featureLog.dateTime,
                rate: featureLog.rate,
                rawValue: +featureLog.rawValue.replaceAll("h", ""),
                trainers: featureLog.trainers.map((t) => t.id),
                trainees: featureLog.trainees.map((t) => t.id),
            };
            this.selectedFeatureLogId = featureLog.id;
            this.featureLogModalTitle = type === 'training' ? "Edit Training" : "Edit Setup Fee";
            this.featureLogModalOkText = "UPDATE";
            this.addFeatureLog = true;
        },

        editFeatureLog() {
            this.$api
                .put(
                    `/instance-feature-logs/${this.project.id}/${this.selectedFeatureLogId}`,
                    this.featureLog
                )
                .then(() => {
                    this.confirmLoading = false;
                    this.resetFeatureLogData();
                    this.$message.success("Training updated successfully");
                    this.getFeatureLogs();
                    this.getInvoiceDetails();
                })
                .catch((err) => {
                    this.confirmLoading = false;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while updating record. Please try again!"));
					}
                });
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        req: (msg) => ({ required: true, message: msg }),

        resetData() {
			this.selectedPackageId = null;
			this.lotsOrDoors = 0;
			this.trainingHourBlocks = 0;
			this.current = 1;
			this.couponCode = "";
			this.selectedExtraPackages = {};
			this.validCouponCode = "";
		},

        resetPassword() {
            this.confirmLoading = true;

            this.$api
                .post(
                    `/super-admin/users/${this.selectedUser.id}/reset-password`
                )
                .then(() => {
                    this.confirmLoading = false;
                    this.resetPasswordModal = false;
                    this.$message.success(
                        "Successfully send reset password link"
                    );
                    this.selectedUser = {};
                })
                .catch((err) => {
                    this.confirmLoading = false;
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while performing reset password action. please try again!"));
					}
                });
        },

        resetFeatureLogData() {
            this.featureLog = {
                featureName: "training_hours",
                description: "",
                dateTime: null,
				rate: null,
                rawValue: null,
                trainers: [],
                trainees: [],
            };
            this.featureLogModalTitle = "Add New Training";
            this.featureLogModalOkText = "SUBMIT";
            this.selectedFeatureLogId = null;
            this.addFeatureLog = false;
        },

        openFeatureLogModal(type = 'training') {
            this.featureLog = {
                featureName: type === 'training' ? "training_hours" : "setup_fee",
                description: "",
                dateTime: null,
                rate: null,
                rawValue: null,
                trainers: [],
                trainees: [],
            };
            this.featureLogModalTitle = type === 'training' ? "Add New Training" : "Add New Setup Fee";
            this.featureLogModalOkText = "SUBMIT";
            this.addFeatureLog = true;
        },

        lockProject() {
            this.confirmLoading = true;
            this.$api
                .post(`/instances/${this.project.id}/suspend`, { chargeForSuspension: this.chargeForSuspension })
                .then(() => {
                    this.$message.success("Successfully Locked the project");
                    this.SET_PROJECT({ ...this.project, susspended: true });
					this.getInvoiceDetails();
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while locking project. please try again!"));
					}
                })
                .finally(() => {
                    this.confirmLoading = false;
                    this.lockModal = false;
					this.chargeForSuspension = false;
                });
        },

        unlockProject() {
            this.confirmLoading = true;
            this.$api
                .post(`/instances/${this.project.id}/unlock`)
                .then(() => {
                    this.$message.success("Successfully Unlocked the project");
                    this.SET_PROJECT({ ...this.project, susspended: false });
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "Error while unlocking project. please try again!"));
					}
                })
                .finally(() => {
                    this.confirmLoading = false;
                    this.unlockModal = false;
                });
        },

        calculateTotalRefund(refunds) {
            const total = refunds
                .reduce((a, b) => {
                    return a + b.amount;
                }, 0)
                .toFixed(2);
            return total ? "$" + total : "";
        },

		deleteCard(card) {
            if (card.instances && card.instances.length > 0) {
                this.switchCardModal.visible = true;
                this.switchCardModal.card = card;
            } else if (!card.instances || card.instances.length == 0) {
                let self = this;
                this.$confirm({
                    title: "Delete Payment Method",
                    content: (h) => <div>Do you want to delete this card?</div>,
                    okText: "DELETE",
                    okType: "danger",
                    cancelText: "CANCEL",
                    centered: true,
                    onOk() {
                        self.confirmDelete(card);
                    },
                    onCancel() {
                        console.log("Cancel");
                    },
                });
            }
        },
        confirmDelete(item) {
            this.$api
                .delete(`/payment-methods/${item.id}`)
                .then(({ data }) => {
                    this.paymentMethods =
                        this.paymentMethods.filter(
                            (x) => x.id !== data.id
                        );
                })
                .catch((err) => {
					if (err?.response?.status !== 400) {
						this.$message.error(this.$err(err, "An error occurred while deleting payment method. Please try again."));
					}
                });
        },

		cardNumberDetails(card) {
            if (card) {
                let cardNo = card.cardNo;
                let cardType = "";

                if (card.ccBrand === "visa") {
                    cardType = "Visa";
                } else if (card.ccBrand === "mc") {
                    cardType = "MasterCard";
                } else if (card.ccBrand === "amex") {
                    cardType = "AmericanExpress";
                }

                return (
                    cardType +
                    " ending in " +
                    cardNo.substring(cardNo.length - 4)
                );
            }
        },

		onChange(e) {
            this.selectedPaymentMethod = e.target.value;
        },

		updateCard(id) {
			let found = this.paymentMethods.find((x) => x.id == id);
            if (found) {
				this.editCard(found)
            }
        },

		updatePaymentMethodCard() {
			if (this.switchCardModal.option == "")
                return this.$message.error("Please select an option.");
            else if (this.switchCardModal.option == "newCard"){
				this.resetPaymentMethodModalData();
                this.paymentMethodModal.show = true;
			} else {
                this.switchCardModal.loading = true;
				this.$api
                    .post(
                        `/payment-methods/${this.selectedInvoice.instance.id}/change-card/${this.switchCardModal.option}`)
                    .then(({ data }) => {
						this.$message.success("Successfully updated selected payment method");
                        this.currentPaymentMethod = data.paymentMethod;
                        this.closeSwitchCardModal();
                    }).catch((err) => {
						this.switchCardModal.loading = false;
						if (err?.response?.status !== 400) {
							this.$message.error(this.$err(err, "Error while updating payment method for the instance. please try again!"));
						}
					})
            }
		},

		changeRadio(e) {
            this.switchCardModal.option = e.target.value;
        },

		closeSwitchCardModal() {
            this.switchCardModal.visible = false;
            this.switchCardModal.card = {};
            this.switchCardModal.option = "";
			this.switchCardModal.loading = false;
        },

		editCard(item) {
            this.paymentMethodModal.action = "edit";
            this.paymentMethodModal.okText = "Update";
            this.paymentMethodModal.title = "Edit Card Details";
            this.paymentMethodModal.paymentMethodId = item.id;
            this.paymentMethodModal.show = true;
			this.paymentMethodToUpdate = item.details;
        },

		resetPaymentMethodModalData() {
            this.paymentMethodModal.action = "add";
            this.paymentMethodModal.okText = "Add Card";
            this.paymentMethodModal.title = "Add New Card";
            this.paymentMethodModal.paymentMethodId = "item.id";
            this.paymentMethodModal.show = false;
            this.paymentMethodModal.load = false;
			this.paymentMethodToUpdate = null;
			this.loadModal = false;
        },

		getCardNumber(string) {
            let card = string.substring(string.length - 4, string.length);
            return `****${card}`;
        },

		getDiscounts(invoice) {
			return invoice.details.appliedDiscounts.map(d => {
				return `${d.name} - $${d.price.toLocaleString(
					"en-US",
					{
						useGrouping: true,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}
				)} (${invoice.currency || 'USD'})`
			})
		},

		getDeductions(invoice) {
			return invoice.details.deductions.map(d => {
				return `${d.name} - $${d.price.toLocaleString(
					"en-US",
					{
						useGrouping: true,
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					}
				)} (${invoice.currency || 'USD'})`
			})
		},

		bulkDownload() {
			if (!this.selectedInvoiceIds.length || this.$store.state.downloadingInvoices) {
				return
			}
			const invoices = this.invoices.filter(invoice => this.selectedInvoiceIds.includes(invoice.id))

			this.$store.commit("SET_INVOICES", invoices)
		},

		addAddons() {
			this.resetData();
			this.updateAddons = true;
			this.packageExtras = [];

			this.$api.get(`/package-extras?currency=${this.project.currency || "USD"}`).then(({ data }) => {
				this.packageExtras = data;
			})
		},

		onAddOnCheckboxChange(event, extraPackage) {
			const obj = { ...extraPackage, selected: event.target.checked };

			if (!this.selectedExtraPackages['addOns']) {
				this.$set(this.selectedExtraPackages, 'addOns', [])
			}

			const index = this.selectedExtraPackages['addOns'].findIndex(
				(item) => item.id === obj.id
			);
			if (index === -1) {
				this.selectedExtraPackages['addOns'].push(obj);
			} else {
				this.selectedExtraPackages['addOns'][index] = obj;
			}
		},

		async fetchAddOnPriceDetails() {
			try {
				if (!this.selectedExtraPackages['addOns']) {
					this.$set(this.selectedExtraPackages, 'addOns', [])
				}
				const payload = {
					currency: this.project.currency,
					instanceId: this.project.id,
					state: this.project.region,
					country: this.project.country,
					promoCode: this.couponCode,
					partner: (this.project.partner && this.project.partner.id) || null,
					billingOwner: (this.project.billingOwner && this.project.billingOwner.id) || null,
					numberOfTrainingHoursBlock: this.trainingHourBlocks
						? parseInt(this.trainingHourBlocks)
						: 0,
					packageExtras: this.selectedExtraPackages['addOns'].map((extras) => {
						if (extras.selected) {
							return {
								id: extras.id,
								quantity: 1,
							};
						} else {
							return null;
						}
					})
						.filter((pe) => pe),
				};

				if (this.lotsOrDoors && this.lotDoorPackage) {
					payload.packageExtras.push({
						id: this.lotDoorPackage.id,
						quantity: parseInt(this.lotsOrDoors),
					})
				}

				this.validCouponCode = "";

				const { data } = await this.$api.post(`instances/addon-price`, payload)
				this.instancePriceDetails = data;
				if (this.couponCode) {
					data.discounts.forEach((discount) => {
						if (discount.code === this.couponCode) {
							this.validCouponCode = "success";
						}
					});
					if (this.validCouponCode === "validating") {
						this.validCouponCode = "error";
						this.$message.error("Invalid coupon code.");
					} else if (this.validCouponCode === "success") {
						this.$message.success("Coupon code applied successfully.");
					}
				} else {
					this.current++;
				}
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.validCouponCode = this.couponCode ? "error" : "";
					this.$message.error(this.$err(err, `An error occurred while fetching addon price details. Please try again.`))
				}
			}
		},

		async addAddOns() {
			try {
				this.loadModal = true;
				const payload = {
					promoCode: this.couponCode,
					numberOfTrainingHoursBlock: this.trainingHourBlocks
						? parseInt(this.trainingHourBlocks)
						: 0,
					packageExtras: this.selectedExtraPackages['addOns'].map((extras) => {
						if (extras.selected) {
							return {
								id: extras.id,
								quantity: 1,
							};
						} else {
							return null;
						}
					}).filter((pe) => pe),
					paymentMode: this.paymentMode
				};

				if (this.paymentMode === 'online') {
					payload.paymentMethod = this.selectedPaymentMethod

					if (!this.selectedPaymentMethod) {
						return this.$message.error('Please add or select payment method.')
					}
				}

				if (this.lotsOrDoors && this.lotDoorPackage) {
					payload.packageExtras.push({
						id: this.lotDoorPackage.id,
						quantity: parseInt(this.lotsOrDoors),
					})
				}

				await this.$api.post(`/instances/${this.project.id}/add-addons`, payload)
				this.$message.success("Project Addons was successfully updated.");
				this.current--;
				this.updateAddons = false;
				this.loadModal = false;
				this.resetData();
				this.goBack();
			} catch (err) {
				this.loadModal = false;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error occurred while updating addons. Please try again!"))
				}
			}
		},

		bulkPayment() {
			if (!this.selectedInvoiceIds.length) {
				return this.$message.warn("Please select invoices");
			}

			let invoices = this.invoices.filter((invoice) => this.selectedInvoiceIds.includes(invoice.id) && !invoice.paid)

			if(invoices.length === 0){
				return this.$message.warn("No valid invoices found. Please select unpaid invoices from list.");
			}

			invoices.forEach(invoice => {
				let total = invoice.total
				if (invoice.payments && invoice.payments.length) {
					const totalPayments = invoice.payments.reduce((total, nextValue) => total + nextValue.amount, 0)
					total = total - totalPayments;
				}

				this.bulkPaymentModal.invoicePayment.paymentDate = moment()
				this.bulkPaymentModal.invoicePayment.instance = this.project.id

				this.bulkPaymentModal.invoicePayment.invoices.push({
					id: invoice.id,
					amount: total,
					invoiceNumber: invoice.invoiceNumber,
					currency: invoice.currency
				})
			})

			this.bulkPaymentModal.show = true;
		},

		async createBulkPayment() {
			this.confirmLoading = true;
			try {
				let payload = JSON.parse(JSON.stringify(this.bulkPaymentModal.invoicePayment))
				payload.invoices = payload.invoices.map((invoice) => {
					return {
						id: invoice.id,
						amount: +invoice.amount,
					}
				})
				payload.paymentDate = +moment(payload.paymentDate).format("x");
				await this.$api.post(`invoice-payments/bulk-create`, payload)
				this.confirmLoading = false;
				this.$message.success("Invoices payment record successfully created.");
				this.getInvoiceDetails();
				this.resetInvoicePaymentData();
			} catch (err) {
				this.confirmLoading = false;
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while trying create bulk payment records. Please try again.`))
				}
			}
		}
    },

	async mounted() {
		if (!this.project || !this.project.id) {
			return this.$router.push('/projects');
		}
		let users = await this.$api.get(`/super-admin/users?email_in=joe%40bildhive.com&email_in=nancy%40bildhive.com&email_in=training%40bildhive.com&email_in=justin%40bildhive.com`);
		this.trainersPerformers = users.data.sort((a, b) => a.email.localeCompare(b.email))
	},
};
</script>

<style lang="scss" scoped>
.selected-activity {
	background-color: var(--primary);
	border-radius: 4px;
	color: #fff;
}
.activity {
	padding: 3px 7px;
	font-size: medium;
}
.project-button {
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    padding: 2px 8px;
}

.card-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 20px;
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.invoice-card-grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    gap: 20px;
    @media screen and (min-width: 768px) {
        grid-template-columns: 1.5fr 1fr 1fr;
    }
}

.gray-button {
    background-color: #fff;
    color: var(--gray);
    border: 1px solid var(--gray);
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
}

.ant-steps {
    width: 99%;
}

.ant-btn-group {
    > .ant-btn {
        border-radius: 4px !important;
    }
}
.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 175px;
}

.grid-display {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.75rem;
}
.type-card {
    border: 2px solid #d0c9d6;
    box-shadow: #e1dede 1px 1px 5px;
    border-radius: 4px;
    cursor: pointer;
}
.type-card:hover {
    border-color: var(--orange);
}

.selected-package {
    border: 2px solid var(--orange);
    border-radius: 4px;
}

.ant-radio-group-large .ant-radio-button-wrapper {
    line-height: 2;
}

.ant-tooltip .ant-tooltip-inner {
    background: #1b1c31;
}

.checkbox-label {
    font-size: 18px;
    color: rgb(0, 0, 0);
    margin: 0 0 0 25px;
}

.new-package-inputs {
    font-size: 16px;
    color: rgb(0, 0, 0);
    margin: 0px;
}

.feature-title {
    font-size: 18px;
    color: var(--orange);
    text-align: left;
    margin: 20px 0 0 0;
}

.ant-list-item {
    padding: 10px 0 0 0;
    border-bottom: 0px !important;
}

.list-view {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: #e1dede 1px 1px 5px;
    border: none;
}

.popoverContent {
    height: max-content;
    line-height: 30px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}

.modal-list {
    .modal-list-title {
        color: #40454c;
        font-size: clamp(16px, 2vw, 18px);
        line-height: clamp(20px, 2vw, 24px);
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
    }
    ul {
        list-style: none;
    }
}
.edit-delete {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    transition: color 0.1s ease-in;
}
</style>


<style lang="scss">
.popoverStyle .ant-popover-inner-content {
    padding: 5px 10px;
    background-color: white;
    border-radius: 20px;
}
</style>
